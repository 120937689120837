import { Alert, styled } from "@enerbit/base";


const StyledAlert = styled(Alert)(({ severity }) => {
  const baseStyles = {
    color: "#333",
    borderRadius: "12px", // bordes redondeados
    display: "flex",
    alignItems: "center",
    "& .MuiAlert-message": {
      width: "100%",
    },
  };

  switch (severity) {
    case "warning":
      return {
        ...baseStyles,
        borderColor: "#FF7705",
        "& .MuiAlert-icon": {
          color: "#fff",
          backgroundColor: "#FF7705",
          borderRadius: "8px", // bordes redondeados
          padding: "8px", // espacio entre el ícono y el borde
          width: "36px", // dimensiones del cuadrado
          height: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .MuiButton-root": {
          color: "white", // o cualquier otro color que desees
          borderColor: "#FF7705",
        },
      };
    case "error":
      return {
        ...baseStyles,
        borderColor: "#F04438",
        color: "#333",
        "& .MuiAlert-icon": {
          color: "#fff",
          backgroundColor: "#F04438",
          borderRadius: "8px", // bordes redondeados
          padding: "8px", // espacio entre el ícono y el borde
          width: "36px", // dimensiones del cuadrado
          height: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      };
    case "success":
      return {
        ...baseStyles,
        borderColor: "#12B76A",
        color: "#333",
        "& .MuiAlert-icon": {
          color: "#fff",
          backgroundColor: "#12B76A",
          borderRadius: "8px", // bordes redondeados
          padding: "8px", // espacio entre el ícono y el borde
          width: "36px", // dimensiones del cuadrado
          height: "36px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      };
    default:
      return {};
  }
});

export default StyledAlert;
