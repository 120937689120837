import { Grid, Typography, Box, Button, RemoveRedEyeOutlinedIcon } from "@enerbit/base";
import moment from "moment";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { loadImage } from "../../../services";
import { PreviewImageModal } from "../modals";

export const FileImageCancel = () => {

    const { getValues } = useFormContext();
    const [previewImage, setPreviewImage] = useState('');
    const [openPreviewImage, setOpenPreviewImage] = useState(false);

    const handleOpenPreviewImage = async () => {
        await loadImage(getValues(`order_cancel.picture_url`)).then((url) => {
            setPreviewImage(url);
            setOpenPreviewImage(true);
        })
    };
    const handleClosePreviewImage = () => setOpenPreviewImage(false);

    return (
        <Grid
            item
            container
            xs={12}
            spacing={3}
        >
            <Grid item xs={12}>
                <Box
                    width="100%"
                    className='box-certification-edit'
                >
                    <Box
                        width={"100%"}
                        p={'16px 32px'}
                    >
                        <Grid container spacing={2}>
                            <Grid container item xs={4} xl={6} display="flex" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography component="p" variant="h6" sx={{ color: "#53358E", fontWeight: 'bold' }}>
                                        Imagen
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Fecha de emisión: {
                                            moment(getValues(`order_cancel.created_at`)).format('YYYY/MM/DD')
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container xs={8} xl={6} item spacing={2} display="flex" justifyContent="end">
                                <Grid item>
                                    <Button
                                        id="view-cancel-image"
                                        variant='contained'
                                        startIcon={<RemoveRedEyeOutlinedIcon />}
                                        color='secondary'
                                        onClick={handleOpenPreviewImage}
                                    >
                                        Ver foto
                                    </Button>
                                    <PreviewImageModal
                                        open={openPreviewImage}
                                        handleClose={handleClosePreviewImage}
                                        previewImage={previewImage}
                                        label={'Imagen - Cancelación'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )
}
