import {
  Grid,
  AdapterDayjs,
  DateTimePicker,
  InputLabel,
  LocalizationProvider,
  Typography,
  dayjs,
} from "@enerbit/base";
import { useEffect } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store/store";
import {
  getInitialMinDate,
  getInitialMinTime,
  getFinalMinDate,
  getFinalMaxDate,
  getFinalMinTime,
  getFinalMaxTime,
} from "../../../helpers";
import { OperatorContainer } from "../operator/OperatorContainer";
import { SearchOperator } from "../search-operator";

export const InfoOrder = () => {
  const { action } = useAppSelector((state) => state.initialForm);
  const {
    control,
    watch,
    resetField,
    formState: { errors },
    setValue,
  } = useFormContext();

  const initialDate = watch("planned_date_begin") || "";
  const finalDate = watch("planned_date_end") || "";

  useEffect(() => {
    if (watch("planned_date_end")) {
      resetField("planned_date_end");
    }
  }, [initialDate]);

  return (
    <Grid item xs={12}>
      <Typography
        color="primary"
        sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
      >
        Información de la orden
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={3} py={3}>
          <Grid item md={6} xs={12}>
            <InputLabel className="title-input-edition" required>
              Fecha y hora de inicio de la orden
            </InputLabel>
            <Controller
              name={"planned_date_begin"}
              control={control}
              render={({ field: { value } }) => (
                <DateTimePicker
                  disabled={action?.type === "Consult"}
                  format="YYYY/MM/DD hh:mm a"
                  value={value ? dayjs(value) : null}
                  disablePast
                  minDate={getInitialMinDate()}
                  minTime={getInitialMinTime(initialDate)}
                  onChange={(date) => {
                    const dateStr =
                      date && date.isValid() ? date.toISOString() : null;
                    setValue("planned_date_begin", dateStr);
                  }}
                  slotProps={{
                    textField: {
                      id: "planned_date_begin",
                      color: "primary",
                      helperText: get(errors, "planned_date_begin")
                        ? get(errors, "planned_date_begin").message
                        : "",
                      error: !!get(errors, "planned_date_begin"),
                      sx: {
                        "& fieldset": { borderRadius: "14px" },
                        "& p": { color: "red" },
                        width: "100%",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel className="title-input-edition" required>
              Fecha y hora de la finalización de la orden
            </InputLabel>
            <Controller
              name={"planned_date_end"}
              control={control}
              render={({ field: { value } }) => (
                <DateTimePicker
                  disabled={action?.type === "Consult"}
                  value={value ? dayjs(value) : null}
                  format="YYYY/MM/DD hh:mm a"
                  disablePast
                  minDate={getFinalMinDate(initialDate)}
                  maxDate={getFinalMaxDate(initialDate)}
                  minTime={getFinalMinTime(initialDate, finalDate)}
                  maxTime={getFinalMaxTime(initialDate, finalDate)}
                  onChange={(date) => {
                    const dateStr =
                      date && date.isValid() ? date.toISOString() : null;
                    setValue("planned_date_end", dateStr);
                  }}
                  slotProps={{
                    textField: {
                      id: "planned_date_end",
                      color: "primary",
                      helperText: get(errors, "planned_date_end")
                        ? get(errors, "planned_date_end").message
                        : "",
                      error: !!get(errors, "planned_date_end"),
                      sx: {
                        "& fieldset": { borderRadius: "14px" },
                        "& p": { color: "red" },
                        width: "100%",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          {action?.type !== "Consult" && <SearchOperator />}
          <OperatorContainer />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};
