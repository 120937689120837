import { Grid, Stepper } from "@enerbit/base";
import { get } from "lodash";
import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { stepperList } from "../../../const/stepper.config";
import { useAppSelector } from "../../../store";
import { FormItem } from "./FormItem";
import { StepItem } from "./StepItem";

export const StepperContent = () => {
  const { detailsOrder, activeStep, filteredKeysJSON, isNotSection } =
    useAppSelector((state) => state.detailProgress);
  const { activity, selectedOrder } = useAppSelector(
    (state) => state.initialForm
  );
  const methods = useForm();
  const { reset } = methods;

  useEffect(() => {
    reset(detailsOrder);
  }, [detailsOrder, reset]);

  const filteredKeysStepper = useMemo(() => {
    return stepperList.filter(({ sectionName, code }) => {
      if (
        [
          "meters_technical_test",
          "request_technical_support",
          "diagrams",
          "main_meter_comunications_installed",
          "main_meter_comunications",
        ].includes(sectionName) &&
        code &&
        selectedOrder &&
        code.includes(selectedOrder?.order_type.code)
      ) {
        return false;
      }
      return (
        get(detailsOrder, sectionName) !== undefined &&
        (activity?.code === "SSE" ? code?.includes(activity.code) : true)
      );
    });
  }, [detailsOrder, selectedOrder, activity]);

  return (
    <FormProvider {...methods}>
      {isNotSection ? (
        <>
          {filteredKeysJSON && filteredKeysJSON?.length > 0 && (
            <Grid container spacing={3}>
              {filteredKeysJSON.map((field, index) => (
                <FormItem field={field} key={index} />
              ))}
            </Grid>
          )}
        </>
      ) : (
        <>
          {filteredKeysStepper.length > 0 && (
            <Stepper activeStep={activeStep} orientation="vertical">
              {filteredKeysStepper.map((step, index) => {
                return (
                  <StepItem
                    key={index}
                    index={index}
                    step={step}
                    data={detailsOrder}
                  />
                );
              })}
            </Stepper>
          )}
        </>
      )}
    </FormProvider>
  );
};
