import { IJsonForm } from "../../models/IDetailsCertificates";

export const photographicAnnexes: IJsonForm[] = [
  {
    label: "Anexo",
    isSection: true,
    type: "array",
    json: [
      {
        label: "Foto",
        name: "photographic_annex.picture_url",
        type: "image",
      },
      {
        label: "Observaciones",
        name: "photographic_annex.observation",
        type: "text-area",
        sx: {
          xs: 12,
        },
      },
    ],
  },
];
