/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  enerbitColors,
  IconButton,
  Tooltip,
  CheckIcon,
  ContentCopyIcon,
} from "@enerbit/base";
import { Handle, NodeProps, Position } from "reactflow";
import { useState } from "react";
import { ISmartBitItem } from "../../../models";

export const MeterNode = ({ data }: NodeProps<ISmartBitItem>) => {
  const { meter } = data;
  const [copied, setCopied] = useState(false); // Nuevo estado para controlar el ícono

  const handleCopy = () => {
    navigator.clipboard.writeText(meter.serial);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box
      sx={{
        width: "100px",
        borderColor: "red",
        backgroundColor: "#fff",
        borderRadius: "3px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Typography
          sx={{
            fontSize: "7px",
            color: meter.validated
              ? enerbitColors.success.main
              : enerbitColors.error.main,
          }}
        >
          Medidor: <b>{meter.validated ? "Validado" : "No validado"}</b>
        </Typography>
        <Tooltip title={copied ? "Copiado" : ""} arrow placement="top">
          <IconButton
            sx={{ fontSize: "7px" }}
            size="small"
            onClick={handleCopy}
          >
            {copied ? (
              <CheckIcon
                sx={{ fontSize: "7px", color: enerbitColors.primary.main }}
              />
            ) : (
              <ContentCopyIcon
                sx={{ fontSize: "7px", color: enerbitColors.primary.main }}
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          backgroundColor: meter.validated ? "#E7F8F0" : "#FEECEB",
          padding: "5px",
          height: "35px",
          borderRadius: "0px 0px 3px 3px",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Typography
          color={meter.validated ? enerbitColors.success.main : enerbitColors.error.main}
          fontWeight={700}
          sx={{ fontSize: "12px" }}
        >
          {meter.serial}
        </Typography>
      </Box>
      <Handle type="target" position={Position.Left} id="b" />
    </Box>
  );
};
