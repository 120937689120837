import { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const phase: IJsonForm[] = [
  {
    label: "Serie de la fase",
    name: "serial_number.value",
    type: "text",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Imagen de la serie de la fase",
    name: "serial_number.picture_url",
    type: "image",
  },
  {
    label: "Fabricante",
    name: "manufacturer",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Año de fabricación",
    name: "manufacture_year",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Burden",
    name: "burden",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Cargas para compensación de burden",
    name: "charges_for_burden_compensation",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Relación de transformación",
    name: "transformation_relationship",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Es exterior:",
    type: "select",
    name: "is_exterior",
    items: [
      {
        text: "No",
        value: "false",
      },
      {
        text: "Si",
        value: "true",
      },
    ],
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Corriente nominal primaria",
    name: "primary_current",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Corriente nominal secundaria",
    name: "secundary_nominal_current",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Corriente nominal secundaria",
    name: "secundary_current",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "En relación con la transformación del servicio",
    name: "in_service_transformation_relationship",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tensión nominal primaria",
    name: "primary_nominal_voltage",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tensión nominal primaria",
    name: "primary_voltage",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tensión nominal secundaria",
    name: "secundary_nominal_voltage",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tensión nominal secundaria",
    name: "secundary_voltage",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "En relación con la transformación del servicio",
    name: "service_voltage",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Porcentaje de exactitud clase",
    type: "select",
    name: "percentage_exactitude_class",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Foto donde se vea el sello de calibración de la primera relación",
    name: "calibration_certificate.first_relation_calibration_stamp.picture_url",
    type: "image",
  },
  {
    label: "Foto donde se vea el sello de calibración de la segunda relación",
    name: "calibration_certificate.second_relation_calibration_stamp.picture_url",
    type: "image",
  },
  {
    label: "Foto donde se vea el sello de calibración de la segunda relación",
    name: "calibration_certificate.calibration_stamp.picture_url",
    type: "image",
  },
  {
    label: "Emisor",
    name: "calibration_certificate.issuer",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Fecha de emisión",
    name: "calibration_certificate.issue_date",
    type: "date",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Sello",
    name: "seals",
    type: "seals",
    json: seals,
  },
];
