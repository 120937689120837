export interface IRolesState {
  role: string;
  canCreateOrders: boolean;
}

export enum RoleCode {
  ADM = "ADM",
  STD = "STD",
  OPR = "OPR",
  FTCO = "FTCO",
  TI = "TI",
  EB_AUT = "EB_AUT",
  SPV = "SPV",
  BPO = "BPO",
  PAYMENTS_ADM = "PAYMENTS_ADM",
  COMERCIALES = "comerciales",
  ABASTECIMIENTO = "bastecimiento",
  ALIADO_TECNICO = "aliado_tecnico",
  CONFORMIDAD_DE_LA_MEDIDA = "conformidad_de_la_medida",
  CONTRATISTA = "contratista",
  BITORINS = "bitorins",
  OPERACION_ENERGIA_DIGITAL = "operacion_energia_digital",
}
