import { Box, Typography, Grid } from "@enerbit/base";
import { FC } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IJsonForm } from "../../../../models/IDetailsCertificates";
import { FormItem } from "../FormItem";

export const SealsForm: FC<IJsonForm> = ({ name, json, label }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: name ?? "",
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Grid key={item.id} item container xs={12}>
            <Box width="100%" className="box-certification-edit" p={4}>
              <Grid item width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography sx={{ fontWeight: "bold", color: "#53358E" }}>
                    {label} {index + 1}
                  </Typography>
                </Box>
              </Grid>
              <Grid item container xs={12} rowSpacing={3} columnSpacing={5}>
                {json?.map((fieldItem: IJsonForm, indexJson) => {
                  const currentField = {
                    ...fieldItem,
                    name: `${name}.${index}.${fieldItem.name}`,
                  };
                  return <FormItem field={currentField} key={indexJson} />;
                })}
              </Grid>
            </Box>
          </Grid>
        );
      })}
    </>
  );
};
