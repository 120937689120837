import { IJsonForm } from "../../models/IDetailsCertificates";
import { phase } from "./phase";

export const voltageTransformerInstalled: IJsonForm[] = [
  {
    label: "La caja/Panel es:",
    type: "select",
    name: "is_installed",
    items: [
      {
        text: "Encontrado",
        value: "false",
      },
      {
        text: "Instalado",
        value: "true",
      },
    ],
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Factor de liquidación externo",
    name: "voltage_external_liquidation_factor",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Ubicación",
    name: "location.value",
    type: "select",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Ubicación",
    name: "location.picture_url",
    type: "image",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Fase R",
    name: "r_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase S",
    name: "s_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase T",
    name: "t_phase",
    type: "phase",
    json: phase,
  },
];
