import { IJsonForm } from "../../models/IDetailsCertificates";

export const signatures: IJsonForm[] = [
  { label: "Usuario", name: "customer", type: "title" },
  {
    label: "Nombre completo",
    name: "customer.fullname",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tipo de documento de identidad",
    name: "customer.legal_id_type",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Número de documento de identidad",
    name: "customer.legal_id_code",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Correo electrónico",
    name: "customer.email",
    type: "email",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: null,
    name: "customer.signature",
    type: "signature",
  },
  {
    label: "Técnico/Testigo",
    name: "technician",
    type: "title",
  },
  {
    label: "Nombre completo",
    name: "technician.fullname",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tipo de documento de identidad",
    name: "technician.legal_id_type",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Número de documento de identidad",
    name: "technician.legal_id_code",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Correo electrónico",
    name: "technician.email",
    type: "email",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: null,
    name: "technician.signature",
    type: "signature",
  },

  {
    label: "Representante frontera",
    name: "frontier",
    type: "title",
  },
  {
    label: "Nombre completo",
    name: "frontier.fullname",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tipo de documento de identidad",
    name: "frontier.legal_id_type",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Número de documento de identidad",
    name: "frontier.legal_id_code",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Correo electrónico",
    name: "frontier.email",
    type: "email",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: null,
    name: "frontier.signature",
    type: "signature",
  },
  {
    label: "Operador de red",
    name: "dso",
    type: "title",
  },
  {
    label: "Nombre completo",
    name: "dso.fullname",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tipo de documento de identidad",
    name: "dso.legal_id_type",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Número de documento de identidad",
    name: "dso.legal_id_code",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Correo electrónico",
    name: "dso.email",
    type: "email",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: null,
    name: "dso.signature",
    type: "signature",
  },
];
