import { Grid, InputLabel } from "@enerbit/base";
import { FC, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { IJsonForm } from "../../../../models/IDetailsCertificates";
import { useFormContext } from "react-hook-form";

interface ChangeViewProps {
  name: string;
  geo: L.LatLngExpression;
}

const ChangeView: FC<ChangeViewProps> = ({ name, geo }) => {
  const map = useMap();
  const { watch } = useFormContext();

  useEffect(() => {
    const newGeo: L.LatLngExpression = [
      watch(`${name}.latitude`) ?? 4.35,
      watch(`${name}.longitude`) ?? -74.04,
    ];
    map.flyTo(newGeo, 12);
  }, [watch(`${name}`), geo, map]);

  return null; // no need to return anything from here
};

export const MapForm: FC<IJsonForm> = ({ name, label }) => {
  const { watch } = useFormContext();
  const [geo, setGeo] = useState<L.LatLngExpression>([4.53, -74.04]);

  useEffect(() => {
    setGeo([
      watch(`${name}.latitude`) ?? 4.35,
      watch(`${name}.longitude`) ?? -74.04,
    ]);
  }, [watch(`${name}`)]);

  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={12}>
        <InputLabel className="title-input-edition">{label}</InputLabel>
        <MapContainer
          style={{ borderRadius: "14px", height: "250px" }}
          center={geo}
          zoom={12}
          zoomControl={false}
          scrollWheelZoom={true}
          attributionControl={false}
        >
          <ChangeView name={name ?? ""} geo={geo} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={geo}
            icon={L.icon({
              iconUrl: 'https://ebdevmicrofront.blob.core.windows.net/resources/bombi-cheerful.webp',
              iconSize: [40, 60],
            })}
          >
            <Popup>Tú Estás Aquí</Popup>
          </Marker>
          <ZoomControl position="bottomright" />
        </MapContainer>
      </Grid>
    </Grid>
  );
};
