import { Grid, Box, Skeleton } from "@enerbit/base";


export const EntityFilteredSkeleton = () => {
  return (
    <Grid item xs={12}>
      <Box className={"box-certification-edit"} p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" width="60%" height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
