import {
  Container,
  Grid,
  IconButton,
  ArrowCircleLeftOutlinedIcon,
  Typography,
  Box,
} from "@enerbit/base";
import { useEffect, useMemo } from "react";
import * as singleSpa from "single-spa";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CertificationsAssociated } from ".";
import { EEntity } from "../../../helpers";
import { OrderEntityGetOrders } from "../../../models";

export const Certifications = () => {
  const dispatch = useAppDispatch();
  const { selectedOrder, addedEntities, action } = useAppSelector(
    (state) => state.initialForm
  );

  const mainEntity: OrderEntityGetOrders | null = useMemo(() => {
    if (!selectedOrder) return null;

    const targetId =
      action?.entity === EEntity.Client && addedEntities[0].secondary_id
        ? addedEntities[0].secondary_id
        : addedEntities[0].id;

    if (selectedOrder.order_entities.length === 1) {
      const singleEntity = selectedOrder.order_entities[0];
      return singleEntity.status !== "cancel" ? singleEntity : null;
    }
    return (
      selectedOrder.order_entities.find(
        (entity) =>
          entity.assignee_id === targetId && entity.status !== "cancel"
      ) ?? null
    );
  }, [selectedOrder, action, addedEntities]);

  const associatedEntities = useMemo(() => {
    if (!selectedOrder || selectedOrder.order_entities.length <= 1) {
      return [];
    }

    return selectedOrder.order_entities.filter(
      (entity) =>
        entity.assignee_id !== addedEntities[0].id && entity.status !== "cancel"
    );
  }, [selectedOrder, addedEntities]);

  useEffect(() => {
    if (mainEntity) {
      const appName = "app-certifications";
      singleSpa.registerApplication({
        name: appName,
        app: () =>
          System.import("@enerBit/certifications").then((module) => {
            if (module.bootstrap && module.mount && module.unmount) {
              return {
                bootstrap: module.bootstrap,
                mount: module.mount,
                unmount: module.unmount,
              };
            } else {
              throw new Error(
                "Module does not export Single SPA lifecycle methods."
              );
            }
          }),
        activeWhen: (location) => location.hash.includes("/orders"),
        customProps: { order: selectedOrder, mainLead: mainEntity },
      });

      singleSpa.start();

      return () => {
        singleSpa.getAppStatus(appName) === "MOUNTED" &&
          singleSpa.unloadApplication(appName);
        singleSpa.unregisterApplication(appName);
      };
    }
  }, [mainEntity, selectedOrder]);

  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => dispatch(setAction(null))}
          >
            <ArrowCircleLeftOutlinedIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10} display="flex" alignItems={"center"}>
          <Typography
            color="primary"
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              mb: "12px",
              display: "flex",
              flex: 1,
            }}
          >
            Ver acta
          </Typography>
        </Grid>
        {associatedEntities.length > 0 && selectedOrder && (
          <CertificationsAssociated
            associatedLeads={associatedEntities}
            order={selectedOrder}
          />
        )}
      </Grid>
      {mainEntity && (
        <Box sx={{ border: "1px solid #A3A9B6", borderRadius: "14px", p: 2 }}>
          <Grid item>
            <Typography
              color="primary"
              sx={{
                fontSize: "21px",
                fontWeight: 700,
                mb: "12px",
                display: "flex",
                flex: 1,
              }}
            >
              Acta principal
            </Typography>
          </Grid>
          <div id="single-spa-application:app-certifications"></div>
        </Box>
      )}
    </Container>
  );
};
