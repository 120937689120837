import { Grid, Box, Pagination } from "@enerbit/base";
import { ChangeEvent, FC } from "react";
import { EEntity, formatDate } from "../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { EmptyData } from "../empty-data";
import { setPage } from "../../../store/slices/list-orders/listOrders";

interface Props {
  entity: EEntity;
  size: number;
}

const entityDictionary: Record<EEntity, string> = {
  MP: "cliente",
  LD: "lead",
  SMB: "smartBit",
};

export const PaginationOrders: FC<Props> = ({ entity, size }) => {
  const { totalOrders, dateRange, loadingOrder, page,listOrders } = useAppSelector(
    (state) => state.listOrders.orders[entity]
  );
  const dispatch = useAppDispatch();

  const handlePageChange = (_: ChangeEvent<unknown>, value: number): void => {
    dispatch(setPage({ page: value, entity }));
  };

  const totalPages = Math.ceil(totalOrders / size);

  return (
    <Grid item xs={12}>
      {listOrders.length === 0 && !loadingOrder ? (
        <EmptyData
        message={
          dateRange.startDate && dateRange.endDate
            ? `No se encontraron órdenes para este ${entityDictionary[entity]} en el rango de ${formatDate(dateRange.startDate)} a ${formatDate(dateRange.endDate)}.`
            : `No se encontraron órdenes para este ${entityDictionary[entity]}.`
        }
      />
      
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Box>
      )}
    </Grid>
  );
};
