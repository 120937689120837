import { ComponentType } from "react";
import { IJsonForm, IControlType } from "../../../models/IDetailsCertificates";
import {
  TextForm,
  SelectForm,
  ImageForm,
  DateForm,
  SwitchForm,
  ArrayForm,
  MapForm,
  SealsForm,
  TitleForm,
  SignatureForm,
  PhaseForm,
  SignalForm,
  MatrixForm,
  ImageSealForm,
  CheckboxForm,
} from "./items";

export const ComponentMap: Record<IControlType, ComponentType<IJsonForm>> = {
  text: TextForm,
  email: TextForm,
  number: TextForm,
  "text-area": TextForm,
  select: SelectForm,
  image: ImageForm,
  "image-seal": ImageSealForm,
  date: DateForm,
  switch: SwitchForm,
  array: ArrayForm,
  matrix: MatrixForm,
  geolocation: MapForm,
  seals: SealsForm,
  title: TitleForm,
  signature: SignatureForm,
  phase: PhaseForm,
  signal: SignalForm,
  checkbox: CheckboxForm,
};
