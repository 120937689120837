/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC } from "react";
import {
  Box,
  Grid,
  Typography,
  motion,
  Stack,
  Tooltip,
  CloseIcon,
  ContentPasteSearchOutlinedIcon,
  EditOutlinedIcon,
  RemoveRedEyeOutlinedIcon,
} from "@enerbit/base";
import { StyledChip, StyledIconButton } from "../../../common";
import { ActionType, ItemGetOrders, OrderType } from "../../../models";
import { EEntity, formatDate } from "../../../helpers";
import { useAppDispatch } from "../../../store/store";
import {
  selectOrder,
  setAction,
} from "../../../store/slices/initial-form/initialForm";
import { enabledCertificates } from "../../../helpers/enabledCertificate";

interface Props {
  order: ItemGetOrders;
  entity: EEntity;
  index: number;
}

export const OrderItem: FC<Props> = ({ order, entity, index }) => {
  const { order_type, planned_date_begin, status } = order;

  const dispatch = useAppDispatch();

  const handleAction = (action: ActionType, activity: OrderType) => {
    dispatch(selectOrder(order));
    dispatch(
      setAction({
        type: action,
        entity,
        activity,
      })
    );
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <motion.div
        initial={{ opacity: 0, x: -10 }} // El estado inicial del elemento: transparente y un poco arriba
        animate={{ opacity: 1, x: 0 }} // El estado final del elemento: opaco y en su posición original
        exit={{ opacity: 0, x: -10 }} // El estado de salida, si lo necesitas, sería desaparecer y moverse un poco arriba
        transition={{ delay: index * 0.25 }} // El delay hace que cada elemento empiece su animación medio segundo después del anterior
        style={{ display: "flex", height: "100%" }}
      >
        <Box
          sx={{
            p: 3,
            border: "1px solid #E4E7EC",
            borderRadius: "12px",
            color: "#667085",
            display: "flex",
            height: "100%", // Asegura que tome toda la altura disponible
            width: "100%",
          }}
        >
          <Box width={"60%"}>
            <Typography fontSize={"18px"} fontWeight="bold">
              {order_type.name}
            </Typography>
            <Typography fontSize={"14px"}>
              Fecha: {formatDate(planned_date_begin)}
            </Typography>
            <Box mt={2}>
              {status === "done" && (
                <StyledChip
                  label="Completada"
                  sx={{
                    background: "#E7F8F0",
                    color: "#12B76A",
                  }}
                />
              )}
              {status === "pending" && (
                <StyledChip
                  label="Pendiente"
                  sx={{ background: "#FFEDDC", color: "#F79009" }}
                />
              )}
              {status === "cancel" && (
                <StyledChip
                  label="Cancelada"
                  sx={{ background: "#FEECEB", color: "#F04438" }}
                />
              )}
              {status === "partial_done" && (
                <StyledChip
                  label="Completada parcial"
                  sx={{
                    background: "#EAF0FA",
                    color: "#3B82F6",
                    border: "2px dashed #3B82F6",
                  }}
                />
              )}
            </Box>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: "40%", justifyContent: "end", alignItems: "center" }}
          >
            {status === "pending" && (
              <>
                <Tooltip title="Editar orden de servicio">
                  <StyledIconButton
                    color="warning"
                    onClick={() => handleAction("Edit", order_type)}
                  >
                    <EditOutlinedIcon />
                  </StyledIconButton>
                </Tooltip>
                <Tooltip title="Cancelar orden de servicio">
                  <StyledIconButton
                    color="error"
                    onClick={() => handleAction("Cancel", order_type)}
                  >
                    <CloseIcon />
                  </StyledIconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Detalle de la orden">
              <StyledIconButton
                color="primary"
                onClick={() => handleAction("Consult", order_type)}
              >
                <RemoveRedEyeOutlinedIcon />
              </StyledIconButton>
            </Tooltip>
            {status !== "cancel" &&
              status !== "pending" &&
              enabledCertificates(order_type.code) && (
                <Tooltip title="Ver acta">
                  <StyledIconButton
                    color="info"
                    onClick={() =>
                      handleAction("Certifications", order_type)
                    }
                  >
                    <ContentPasteSearchOutlinedIcon />
                  </StyledIconButton>
                </Tooltip>
              )}
          </Stack>
        </Box>
      </motion.div>
    </Grid>
  );
};
