import { IJsonForm } from "../../models/IDetailsCertificates";

export const signal: IJsonForm[] = [
  {
    label: "Calibre",
    name: "gauge.value",
    type: "text",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Imagen de la serie de la fase",
    name: "gauge.picture_url",
    type: "image",
  },
  {
    label: "Denominación",
    name: "denomination",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Longitud (m) conductor señal de corriente",
    name: "length",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "N° de conductores",
    name: "count",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Material conductor",
    name: "material",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Fabricante",
    name: "manufacturer",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
];
