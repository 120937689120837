import { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const mainMeterRetired: IJsonForm[] = [
  {
    name: "meter_serial.value",
    type: "text",
    label: "Serie medidor",
    sx: {
      xs: 12,
    },
  },
  {
    name: "meter_serial.picture_url",
    type: "image",
    label: "Foto del medidor",
  },
  {
    name: "meter_brand",
    type: "text",
    label: "Marca medidor",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "ownership",
    type: "select",
    label: "Propiedad del medidor",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "measurement_type",
    type: "select",
    label: "Tipo de medición",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "active_energy_imported.value",
    type: "number",
    label: "Lectura importación activa",
    sx: {
      xs: 12,
    },
  },
  {
    name: "active_energy_imported.picture_url",
    type: "image",
    label: "Foto de lectura importación activa",
  },
  {
    name: "active_energy_exported.value",
    type: "number",
    label: "Lectura exportación activa",
    sx: {
      xs: 12,
    },
  },
  {
    name: "active_energy_exported.picture_url",
    type: "image",
    label: "Foto de lectura exportación activa",
  },
  {
    name: "reactive_energy_imported.value",
    type: "number",
    label: "Lectura importación reactiva",
    sx: {
      xs: 12,
    },
  },
  {
    name: "reactive_energy_imported.picture_url",
    type: "image",
    label: "Foto de lectura importación reactiva",
  },
  {
    name: "reactive_energy_exported.value",
    type: "number",
    label: "Lectura exportación reactiva",
    sx: {
      xs: 12,
    },
  },
  {
    name: "reactive_energy_exported.picture_url",
    type: "image",
    label: "Foto de lectura exportación reactiva",
  },
  {
    label: "Sello",
    name: "seals",
    type: "seals",
    json: seals,
  },
];
