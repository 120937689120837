/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AdapterDayjs,
  DateTimePicker,
  Dayjs,
  Grid,
  InputLabel,
  LocalizationProvider,
  TextField,
  Typography,
  dayjs,
} from "@enerbit/base";
import { Controller, get, useFormContext } from "react-hook-form";
import { FileImageCancel } from "../file-image";

export const CancellationReason = () => {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleDateTimeChange = (date: Dayjs | null) => {
    const dateStr = date && date.isValid() ? date.toISOString() : null;
    setValue("order_cancel.created_at", dateStr);
  };

  return (
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={6}>
        <Typography
          color="primary"
          sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
        >
          Motivo de cancelación de la orden
        </Typography>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <InputLabel className="title-input-edition" required>
            Fecha y hora de cancelación de la orden
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name={"order_cancel.created_at"}
              control={control}
              render={({ field: { value } }) => (
                <DateTimePicker
                  disabled
                  value={value ? dayjs(value) : null}
                  format="YYYY/MM/DD hh:mm a"
                  disablePast
                  onChange={handleDateTimeChange}
                  slotProps={{
                    textField: {
                      id: "end_date",
                      color: "primary",
                      helperText: get(errors, "order_cancel.created_at")
                        ? get(errors, "order_cancel.created_at").message
                        : "",
                      error: !!get(errors, "order_cancel.created_at"),
                      sx: {
                        "& fieldset": { borderRadius: "14px" },
                        "& p": { color: "red" },
                        width: "100%",
                      },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <InputLabel className="title-input-edition" required>
            Motivo de cancelación de la orden
          </InputLabel>
          <TextField
            fullWidth
            disabled
            sx={{ "& fieldset": { borderRadius: "14px" } }}
            value={watch(`order_cancel.cancellation_reason.name`)}
            variant="outlined"
          />
        </Grid>
        {watch("order_cancel.picture_url") && <FileImageCancel />}
        <Grid item xs={12}>
          <InputLabel className="title-input-edition" required>
            Observaciones de la cancelación
          </InputLabel>
          <TextField
            fullWidth
            disabled
            sx={{ "& fieldset": { borderRadius: "14px" } }}
            value={watch(`order_cancel.observations`)}
            variant="outlined"
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
