import { ItemActivities } from "../models";
import { RoleCode } from "../models/IRoles";
import { EEntity } from "./common";

const activityToEntityMap: { [key: string]: EEntity[] } = {
  // Actividades asociadas a EEntity.Lead
  CS: [EEntity.Lead],
  CCE: [EEntity.Lead],
  IPCCE: [EEntity.Lead],
  NS: [EEntity.Lead],
  CCEG: [EEntity.Lead],
  IG: [EEntity.Lead],
  NSG: [EEntity.Lead],
  CSG: [EEntity.Lead],
  IPP: [EEntity.Lead],
  NSDG: [EEntity.Lead],
  NSDI: [EEntity.Lead],
  NORM_CGM_THIRD: [EEntity.Lead],
  ISBG: [EEntity.Lead],
  INS_SOLAR: [EEntity.Lead, EEntity.Client],
  INST_SOLAR: [EEntity.Lead, EEntity.Client],
  TECH_RECEIPT: [EEntity.Lead],
  RPQR_SOLAR: [EEntity.Lead],
  // Actividades asociadas a EEntity.Client
  AIV: [EEntity.Client],
  ADCSM: [EEntity.Client],
  II: [EEntity.Client],
  "NSCN-RPE": [EEntity.Client],
  RSE: [EEntity.Client],
  RDCE: [EEntity.Client],
  RP: [EEntity.Client, EEntity.Lead],
  SSSE: [EEntity.Client],
  SSE: [EEntity.Client],
  MP: [EEntity.Client],
  NCMP: [EEntity.Client],
  AEMERG: [EEntity.Client],
  DSM: [EEntity.Client],
  NORM_SOLAR: [EEntity.Client],
  // Actividades asociadas a EEntity.Smartbit
  NT: [EEntity.Smartbit],
  //NORM_SOLAR: [EEntity.Smartbit],
  // Actividades con múltiples entidades
  REMOV_CE_NO_INST: [EEntity.Lead, EEntity.Client],
  CC: [EEntity.Lead, EEntity.Client],
};

const activityToRolesMap: { [key: string]: RoleCode[] } = {
  NT: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA], // Normalización del sistema de comunicación
  RSE: [RoleCode.ADM, RoleCode.BITORINS], // Reconexión del servicio de energía
  RDCE: [RoleCode.ADM, RoleCode.BITORINS, RoleCode.CONFORMIDAD_DE_LA_MEDIDA], // Revisión por desviación del consumo de energía
  SSSE: [RoleCode.ADM, RoleCode.BITORINS], // Seguimiento de la suspensión del servicio de energía
  SSE: [RoleCode.ADM, RoleCode.BITORINS], // Suspensión del servicio de energía
  RPSM: [], // Revisión o normalización preventiva del sistema de medida
  CCEG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Cambio de comercializador a enerBit - Gabinete
  NSG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Nuevo servicio - Gabinete
  AIV: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA], // Acompañamiento e inspección visual
  MP: [], // Modo planta
  CSG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Cesión de servicio - Gabinete
  "NSCN-RPE": [RoleCode.ADM, RoleCode.BITORINS], // Normalización de servicio cliente notificado
  NCMP: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA], // Normalización por código de medida o preventiva
  IPP: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Inspección previa - Proyecto
  CCE: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Cambio de comercializador a enerBit - Individual
  CS: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Cesión de servicio - Individual
  II: [RoleCode.ADM, RoleCode.OPERACION_ENERGIA_DIGITAL], // Inspección de interventoria
  IPCCE: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Inspección previa - Individual
  NS: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Nuevo servicio - Individual
  // CM: [], // Cambio de medidor
  NSDG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Nuevo servicio desenergizado - Gabinete
  RP: [RoleCode.ADM, RoleCode.BITORINS], // Revisión por PQR
  NSDI: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Nuevo servicio desenergizado - Individual
  AEMERG: [], // Normalización atención de emergencias
  DSM: [RoleCode.ADM, RoleCode.BITORINS], // Atención cliente con afectación de servicio
  ADCSM: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.OPERACION_ENERGIA_DIGITAL], // Verificación y/o actualización de datos del cliente
  NORM_CGM_THIRD: [], // Normalización CGM de terceros
  IG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA, RoleCode.BPO], // Inspección previa - Gabinete
  CC: [RoleCode.ADM, RoleCode.BITORINS], // Cancelación de cuenta
  REMOV_CE_NO_INST: [RoleCode.ADM, RoleCode.BITORINS], // Cancelación de cuenta sin instalación
  ISBG: [RoleCode.ADM, RoleCode.CONFORMIDAD_DE_LA_MEDIDA], // Cambio de smartBit - Gabinete
};

// Función para filtrar actividades por entidad
export const filterActivitiesByEntityAndRole = (
  activities: ItemActivities[],
  role: string,
  entity?: EEntity
): ItemActivities[] => {
  return activities.filter((activity) => {
    const activityEntities = activityToEntityMap[activity.code];
    // const activityRoles = activityToRolesMap[activity.code];
    // Si se proporciona una entidad, filtra por esa entidad; de lo contrario, devuelve todas las actividades con una entrada en el mapa
    return entity ? activityEntities?.includes(entity) : !!activityEntities;
  });
};

export const validateMultiple = (activity: string) => {
  const activities = [
    "Actualización / Cambio smartBit - Gabinete",
    "Cambio de comercializador a enerBit - Gabinete",
    "Inspección previa - Gabinete",
    "Nuevo servicio - Gabinete",
    "Cesión de servicio - Gabinete",
    "Nuevo servicio desenergizado - Gabinete",
    "Cambio de smartBit - Gabinete",
  ];

  return !!activities.includes(activity);
};

export const validateSection = (activity: string) => {
  const activities = ["AIV", "MP"];

  return !!activities.includes(activity);
};
