import { DialogContent } from "@enerbit/base"
import { FC } from "react"
import { StyledDialog, StyledDialogTitle } from "../../../common"

interface Props {
    open: boolean,
    handleClose: () => void,
    previewImage: string,
    label: string
}

export const PreviewImageModal: FC<Props> = ({ open, handleClose, previewImage, label }) => {
    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {label}
            </StyledDialogTitle>
            <DialogContent>
                <img src={previewImage} alt={label} style={{ width: '100%', height: '500px', objectFit: 'contain' }} />
            </DialogContent>
        </StyledDialog>
    )
}
