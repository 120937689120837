import {
  ArrowCircleLeftOutlinedIcon,
  Grid,
  IconButton,
  Typography,
  UpdateIcon,
} from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../store/store";
import { handleDetails } from "../store/slices/detail-progress/detailProgress";
import { StepperContent } from "./components/stepper/StepperContent";
import { EmptyData } from "./components/empty-data";
import { getOrder } from "../store";

export const Details = () => {
  const { detailsOrder, loadingDetails } = useAppSelector(
    (state) => state.detailProgress
  );
  const { selectedOrder } = useAppSelector(
    (state) => state.initialForm
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => dispatch(handleDetails())}
          >
            <ArrowCircleLeftOutlinedIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10} display="flex" alignItems={"center"}>
          <Typography
            color="primary"
            sx={{ fontSize: "28px", fontWeight: 700, mb: "0px" }}
          >
            Detalle del progreso de la orden
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() =>
              selectedOrder ? dispatch(getOrder(selectedOrder.id)) : null
            }
            disabled={loadingDetails}
            color="primary"
          >
            <UpdateIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {detailsOrder ? (
            <StepperContent />
          ) : (
            <EmptyData message="No se encuentra el detalle de esta orden." />
          )}
        </Grid>
      </Grid>
    </>
  );
};
