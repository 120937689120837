import {
  DialogContent,
  Grid,
  InputLabel,
  Alert,
  Button,
  AddCircleOutlineIcon,
  CircularProgress,
  FormControl,
  InputAdornment,
  SearchIcon,
  TextField,
} from "@enerbit/base";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { EEntity, validateMultiple } from "../../../helpers";
import {
  useAppSelector,
  useAppDispatch,
  getLeads,
  addOrderEntities,
} from "../../../store";
import {
  addLeads,
  setFilter,
  updateEntitiesSelectedOrder,
} from "../../../store/slices/initial-form/initialForm";
import {
  closeAddModal,
  resetList,
  resetTableAddress,
  setName,
  setPage,
  setPageSize,
} from "../../../store/slices/table-address/tableAddress";
import { AddressTableLead } from "../tables";
import { ISelectedEntity, OrderEntityGetOrders } from "../../../models";

export const AddAddressModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    entity,
    activity,
    listEntities,
    selectedOrder,
    action,
    filter,
    addedEntities,
  } = useAppSelector((state) => state.initialForm);
  const {
    selectedLeads,
    selectedCustomers,
    openAdd,
    loading,
    name,
    error,
    messageTable,
  } = useAppSelector((state) => state.tableAddress);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeAddModal());
  };

  const handleAddLeads = async () => {
    if (action?.type === "Create") {
      dispatch(
        addLeads(
          selectedLeads.map((leadItem) => ({
            id: leadItem.id,
            secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
            full_name: `${leadItem.name} ${leadItem.last_name}`,
            address: leadItem.address,
            city: leadItem.city,
            department: leadItem.department,
            email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
          }))
        )
      );
    } else {
      const entitiesToAdd: ISelectedEntity[] = selectedLeads.map(
        (leadItem) => ({
          id: leadItem.id,
          secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
          full_name: `${leadItem.name} ${leadItem.last_name}`,
          address: leadItem.address,
          city: leadItem.city,
          department: leadItem.department,
          email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
        })
      );

      try {
        const actionResponse = await dispatch(
          addOrderEntities({
            entities: entitiesToAdd,
            entity_id:
              listEntities.find(({ code }) => code === entity)?.id || "",
            order_id: selectedOrder?.id || "",
          })
        );

        const currentLead: ISelectedEntity[] =
          actionResponse.payload as ISelectedEntity[];

        // Verifica si la acción fue exitosa
        if (actionResponse.meta.requestStatus === "rejected") {
          // Si la acción no fue exitosa, lanza un error
          throw new Error("Error al agregar entidades");
        }

        // Si la acción fue exitosa, procede a actualizar las entidades

        const currentOrderEntities = selectedOrder?.order_entities || [];
        const newEntities = entitiesToAdd.map(
          ({ full_name, department, city, address },index) => {
            // Asegúrate de que este objeto coincida con la estructura de OrderEntityGetOrders
            const newEntity: OrderEntityGetOrders = {
              id: currentLead[index].secondary_id ?? "",
              assignee_id: currentLead[index].id ?? "",
              form_data: {
                name_witness: null,
                phone_contact_witness: null,
                name_third_contact: null,
                phone_third_contact: null,
                email_third_contact: null,
                observations: null,
              },
              entity: currentOrderEntities[0].entity,
              status: currentOrderEntities[0].status,
              created_at: new Date(),
              updated_at: null,
              pii: {
                full_name: full_name ?? "",
                address: address ?? "",
                estate: department ?? "",
                city: city ?? "",
              },
            };

            return newEntity;
          }
        );

        dispatch(
          updateEntitiesSelectedOrder([...currentOrderEntities, ...newEntities])
        );
      } catch (error) {
        console.error("Error al agregar entidades:", error);
        enqueueSnackbar("Error al agregar el lead", { variant: "error" });
      }
    }
    dispatch(resetTableAddress());
    handleClose();
  };

  const getAddresses = () => {
    if (filter) {
      dispatch(setPage(1));
      dispatch(setPageSize(5));
      dispatch(getLeads({ email: filter || "", name }));
    }
  };

  const debounced = useDebouncedCallback((value) => {
    dispatch(setPage(1));
    dispatch(getLeads({ email: filter || "", name: value }));
  }, 500);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value.trim();
    dispatch(setName(nextValue));
    debounced(nextValue);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(messageTable, { variant: "error" });
    }
  }, [error]);

  useEffect(() => {
    dispatch(setName(""));
    dispatch(setFilter(""));
    dispatch(resetList());
  }, []);

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openAdd}
      maxWidth={"lg"}
      fullWidth
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Predios
      </StyledDialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <InputLabel className="title-input-edition" required>
              Buscar {entity === EEntity.Client ? "cliente" : "lead"}
            </InputLabel>
            <FormControl fullWidth>
              <Grid container spacing={3}>
                <Grid item flex={1}>
                  <TextField
                    fullWidth
                    onKeyDown={(e) => e.key === "Enter" && getAddresses()}
                    disabled={loading}
                    type={EEntity.Smartbit !== entity ? "email" : "text"}
                    id={EEntity.Smartbit !== entity ? "email" : "smartbit"}
                    sx={{ "& fieldset": { borderRadius: "14px" } }}
                    name={"filter"}
                    value={filter}
                    onChange={(e) => dispatch(setFilter(e.target.value))}
                    variant="outlined"
                    placeholder="Buscar"
                    InputProps={{
                      endAdornment: loading && (
                        <InputAdornment position="end">
                          <CircularProgress size={20} thickness={6} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <Button
                    id="search-lead"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    onClick={getAddresses}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputLabel className="title-input-edition">
              Buscar por nombre o dirección
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                fullWidth
                disabled={!filter}
                type="email"
                sx={{ "& fieldset": { borderRadius: "14px" } }}
                value={name}
                onChange={handleSearchChange}
                variant="outlined"
                placeholder="Buscar"
                InputProps={{
                  endAdornment: loading && (
                    <InputAdornment position="end">
                      <CircularProgress size={20} thickness={6} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "#344054", fontSize: "18px" }} required>
              Selecciona el predio de la orden que desea crear
            </InputLabel>
          </Grid>
          {validateMultiple(activity?.code || "") && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Recuerde que para asociar predios todos deben estar en la misma
                ciudad.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <AddressTableLead multiple />
          </Grid>
          <Grid item xs={12}>
            <Button
              id="create-button"
              fullWidth
              disabled={
                !(selectedLeads.length > 0 || selectedCustomers.length > 0)
              }
              variant="contained"
              color="secondary"
              endIcon={<AddCircleOutlineIcon />}
              onClick={handleAddLeads}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
