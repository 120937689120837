/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cleanObject,
  cleanObjectStepper,
  validateSection,
} from "../../../helpers";
import { ItemGetOrders } from "../../../models";

export const getDetailsOrder = createAsyncThunk(
  "detailProgress/getDetailsOrder",
  async (order: ItemGetOrders, { rejectWithValue }) => {
    const { status, id, order_entities, order_type } = order;
    const isNotSection = validateSection(order_type.code);
    try {
      if (status !== "done") {
        const { data } = await api.get<any>(
          `/service-orders-certificates-bkp/bkp/certificates/${id}`
        );
        return {
          detailsOrder: isNotSection
            ? cleanObject(data)
            : cleanObjectStepper(data),
          isNotSection,
        };
      } else {
        const { data } = await api.get<any>(
          `service-orders-certificates/certificates?assignee_id=${order_entities[0].assignee_id}&order_id=${id}`
        );
        return {
          detailsOrder: isNotSection
            ? cleanObject(
                // Filtrar primero y luego encontrar el último elemento
                data.items
                  .filter(
                    ({ certificate_type }: any) =>
                      certificate_type.code !== "HAZRW"
                  )
                  .slice(-1)[0]?.form_data
              )
            : cleanObjectStepper(data.items.slice(-1)[0]?.form_data),
          isNotSection,
        };
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.status);
    }
  }
);
