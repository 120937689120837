import { EEntity } from "../../helpers";
import { RoleCode } from "../../models/IRoles";

export const mapRolesToEntities: Record<string, EEntity[]> = {
  [RoleCode.ADM]: [EEntity.Client, EEntity.Lead, EEntity.Smartbit],
  [RoleCode.BITORINS]: [EEntity.Client, EEntity.Lead, EEntity.Smartbit],
  [RoleCode.CONFORMIDAD_DE_LA_MEDIDA]: [EEntity.Client, EEntity.Smartbit],
  [RoleCode.OPERACION_ENERGIA_DIGITAL]: [EEntity.Client],
  [RoleCode.BPO]: [EEntity.Client, EEntity.Lead],
};

export const createOrderAllowedRoles: RoleCode[] = [
  RoleCode.ADM,
  RoleCode.CONFORMIDAD_DE_LA_MEDIDA,
  RoleCode.BITORINS,
  RoleCode.BPO,
  RoleCode.OPERACION_ENERGIA_DIGITAL,
];

