import { IJsonForm } from "../../models/IDetailsCertificates";

export const metersTechnicalTest: IJsonForm[] = [
  {
    label: "Medidor principal",
    type: "title",
    name: "main_meter",
  },
  {
    name: "main_meter.instantaneus_data.picture_url",
    label: "Foto de los datos instantáneos o multímetro",
    type: "image",
  },
  {
    name: "main_meter.phase_diagram.picture_url",
    label: "Foto del diagrama fasorial",
    type: "image",
  },
  {
    name: "main_meter.accuracy_active_energy.picture_url",
    label: "Foto de exactitud energía activa",
    type: "image",
  },
  {
    name: "main_meter.accuracy_reactive_energy.picture_url",
    label: "Foto de exactitud energía reactiva",
    type: "image",
  },
  {
    name: "main_meter.registry_active_energy.picture_url",
    label: "Foto de registro energía activa",
    type: "image",
  },
  {
    name: "main_meter.registry_reactive_energy.picture_url",
    label: "Foto de exactitud energía reactiva",
    type: "image",
  },
  // {
  //   label: "Medidor opcional",
  //   type: "title",
  //   name: "optional_meter",
  // },
  // {
  //   name: "optional_meter.instantaneus_data.picture_url",
  //   label: "Foto de los datos instantáneos o multímetro",
  //   type: "image",
  // },
  // {
  //   name: "optional_meter.instantaneus_data_total.picture_url",
  //   label: "Foto de los datos instantáneos o multímetro",
  //   type: "image",
  // },
  // {
  //   name: "optional_meter.phase_diagram.picture_url",
  //   label: "Foto del diagrama fasorial",
  //   type: "image",
  // },
  // {
  //   name: "optional_meter.accuracy_active_energy.picture_url",
  //   label: "Foto de exactitud energía activa",
  //   type: "image",
  // },
  // {
  //   name: "optional_meter.accuracy_reactive_energy.picture_url",
  //   label: "Foto de exactitud energía reactiva",
  //   type: "image",
  // },
  // {
  //   name: "optional_meter.registry_reactive_energy.picture_url",
  //   label: "Foto de exactitud energía reactiva",
  //   type: "image",
  // },
  // {
  //   label: "Materiales",
  //   type: "title",
  //   name: "materials",
  // },
  // {
  //   name: "materials.material",
  //   label: "material?",
  //   type: "text",
  //   sx: {
  //     xs: 12,
  //     md: 6,
  //   },
  // },
  // {
  //   name: "materials.serie",
  //   label: "serie?",
  //   type: "text",
  //   sx: {
  //     xs: 12,
  //     md: 6,
  //   },
  // },
];
