import { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const mainMeter: IJsonForm[] = [
  {
    name: "is_installed",
    label: "El medidor es",
    type: "select",
    items: [
      { text: "Instalado", value: true },
      { text: "Encontrado", value: false },
    ],
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "meter_serial.value",
    label: "Serie del medidor",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "meter_serial.picture_url",
    label: "Foto del medidor",
    type: "image",
  },
  {
    name: "meter_location",
    label: "Ubicación del medidor",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "ownership",
    label: "Propiedad del medidor",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "meter_brand",
    label: "Marca del medidor",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "meter_model",
    label: "Modelo(Referencia)",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "measurement_type",
    label: "Tipo de medida",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "number_meter_elements",
    type: "select",
    label: "Número de elementos del medidor",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "number_connection_elements",
    type: "select",
    label: "Número de elementos de la conexión",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "active_class_index_percentage",
    type: "select",
    label: "Índice de clase activa (%)",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "reactive_class_index_percentage",
    type: "select",
    label: "Índice de clase reactiva (%)",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "active_energy_imported",
    type: "number",
    label: "Lectura importación activa",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "active_energy_exported",
    type: "number",
    label: "Lectura exportación activa",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "reactive_energy_imported",
    type: "number",
    label: "Lectura importación reactiva",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "reactive_energy_exported",
    type: "number",
    label: "Lectura exportación reactiva",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Fecha de calibración",
    type: "date",
    name: "calibration_date.value",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Foto del adhesivo de calibración del laboratorio",
    type: "image",
    name: "calibration_date.picture_url",
  },
  {
    label: "Sello",
    name: "seals",
    type: "seals",
    json: seals,
  },
];
