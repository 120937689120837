import moment from "moment";
import { Certificate } from "../models/certificates";

export const getLastCertificate = (certificates: Certificate[]) => {
  if (!certificates || certificates.length === 0) return null;

  let lastDate = moment(certificates[0].created_at);

  let lastCertificate = certificates[0];

  certificates.forEach((objeto) => {
    const currentDate = moment(objeto.created_at);

    if (currentDate.isAfter(lastDate)) {
      lastDate = currentDate;
      lastCertificate = objeto;
    }
  });

  return lastCertificate;
};
