import {
  Typography,
  Container,
  ArrowCircleLeftOutlinedIcon,
  IconButton,
  Grid,
} from "@enerbit/base";
import { useAppSelector } from "../../../store";
import { CreateByActivity } from "../activity";
import { CreateProvider } from "../form-provider";
import { useAppDispatch } from "../../../store/store";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import OrderDescription from "../order/OrderDescription";

export const CreateOrder = () => {
  const { activity, entity } = useAppSelector((state) => state.initialForm);
  const dispatch = useAppDispatch();

  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <IconButton color='primary' size='small' onClick={() => dispatch(setAction(null))}>
            <ArrowCircleLeftOutlinedIcon fontSize='large' />
          </IconButton>
        </Grid>
        <Grid item xs={10} display='flex' alignItems={"center"}>
          <Typography color='primary' sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}>
            Generar orden de servicio
          </Typography>
        </Grid>
      </Grid>
      <CreateByActivity />
      {activity && entity && <OrderDescription activityCode={activity.code} />}
      {activity && entity && <CreateProvider />}
    </Container>
  );
};
