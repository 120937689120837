/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography,
  Grid,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  styled,
  Box,
  KeyboardArrowRightIcon,
} from "@enerbit/base";
import { FC } from "react";
import { useFormContext, get } from "react-hook-form";
import { IStepperList } from "../../../../models/IDetailsCertificates";
import { FormItem } from "../FormItem";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface Props {
  index: number;
  step: IStepperList;
  expanded: boolean | number;
  handleChange: (index: number) => void;
}
export const AccordionItem: FC<Props> = ({
  index,
  step,
  expanded,
  handleChange,
}) => {
  const { watch } = useFormContext();
  const filteredData = step.data.filter(
    ({ name }) =>
      (step.sectionName || name) &&
      get(watch(), name ?? step.sectionName) !== null
  );

  return (
    <Grid item xs={12}>
      <Accordion
        expanded={expanded === index}
        onChange={() => handleChange(index)}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography
            sx={{
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            {index + 1}. {step.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={3} columnSpacing={5}>
            <Box width={"100%"} height={"1px"} />
            {filteredData.map((field, index) => {
              return <FormItem field={field} key={index} />;
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
