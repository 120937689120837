import { IJsonForm } from "../../models/IDetailsCertificates";
import { signal } from "./signal";

export const electricalConductorsInstalled: IJsonForm[] = [
  {
    label: "El conductor es:",
    type: "select",
    name: "is_installed",
    items: [
      {
        text: "Encontrado",
        value: "false",
      },
      {
        text: "Instalado",
        value: "true",
      },
    ],
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Conductores señal de corriente",
    name: "current_signal",
    type: "signal",
    json: signal,
  },
  {
    label: "Conductores señal de tensión",
    name: "voltage_signal",
    type: "signal",
    json: signal,
  },
];
