import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  DeleteOutlineIcon,
  IconButton,
} from "@enerbit/base";
import { FC, useState } from "react";
import { InfoLeadModal } from "../modals";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  handleDeleteLead,
  updateEntitiesSelectedOrder,
} from "../../../store/slices/initial-form/initialForm";
import { deleteOrderEntities } from "../../../store";
import { ISelectedEntity } from "../../../models";
import { enqueueSnackbar } from "notistack";
import { StyledChip } from "../../../common";

interface Props {
  lead: ISelectedEntity;
  isMain?: boolean;
  status?: string;
}

export const CabinetItem: FC<Props> = ({ lead, isMain = false, status }) => {
  const { entity, listEntities, selectedOrder, addedEntities, action } =
    useAppSelector((state) => state.initialForm);
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteLead = async () => {
    if (action?.type === "Create") {
      dispatch(handleDeleteLead(lead.id));
    } else {
      console.log({
        id: lead.secondary_id || "",
        entity_id: listEntities.find(({ code }) => code === entity)?.id || "",
        measurement_point_id: lead.id || "",
      });
      try {
        const actionResponse = await dispatch(
          deleteOrderEntities({
            id: lead.secondary_id || "",
            entity_id:
              listEntities.find(({ code }) => code === entity)?.id || "",
              assignee_id: lead.id || "",
          })
        );

        // Suponiendo que la acción devuelve un objeto con información en 'payload' o 'meta'
        if (actionResponse.meta.requestStatus === "rejected") {
          // Si la acción no fue exitosa, lanza un error
          throw new Error("Error al eliminar entidad");
        }

        // Si la acción fue exitosa, procede a actualizar las entidades
        const updatedOrderEntities = selectedOrder?.order_entities.filter(
          (entity) => entity.assignee_id !== lead.id
        );

        dispatch(updateEntitiesSelectedOrder(updatedOrderEntities));
      } catch (error) {
        // Manejo centralizado de errores
        console.error("Error al eliminar entidades:", error);
        enqueueSnackbar("Error al eliminar el lead", { variant: "error" });
      }
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 3,
          border: "1px solid #D0D5DD",
          height: "100%",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography color="primary" fontWeight="bold" fontSize="18px">
              {lead.full_name}
            </Typography>
            <Typography color="neutral">{lead.address}</Typography>
            <Typography color="neutral" fontWeight={"bold"}>
              {lead.city}
            </Typography>
            <Box mt={1} display={"flex"} flex={1}>
              {status === "done" && (
                <StyledChip
                  label="Completada"
                  sx={{
                    background: "#E7F8F0",
                    color: "#12B76A",
                  }}
                />
              )}
              {status === "pending" && (
                <StyledChip
                  label="Pendiente"
                  sx={{ background: "#FFEDDC", color: "#F79009" }}
                />
              )}
              {status === "cancel" && (
                <StyledChip
                  label="Cancelada"
                  sx={{ background: "#FEECEB", color: "#F04438" }}
                />
              )}
              {status === "partial_done" && (
                <StyledChip
                  label="Completada parcial"
                  sx={{
                    background: "#EAF0FA",
                    color: "#3B82F6",
                    border: "2px dashed #3B82F6",
                  }}
                />
              )}
            </Box>
          </Box>
          {!isMain &&
            action?.type !== "Consult" &&
            action?.type !== "Cancel" &&
            ((action?.type === "Create" && addedEntities.length > 1) ||
              (selectedOrder && selectedOrder?.order_entities.length > 1)) && (
              <Box>
                <IconButton onClick={deleteLead}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>
              </Box>
            )}
        </Box>
        <Box>
          <Divider color="#D0D5DD" sx={{ my: 2 }} />
          <Button
            id="watch-detail"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              border: "3px solid",
              ":hover": { border: "3px solid" },
              fontWeight: "bold",
            }}
            onClick={() => setOpen(true)}
          >
            Detalle
          </Button>
        </Box>
        <InfoLeadModal
          open={open}
          handleClose={() => setOpen(false)}
          lead={lead}
        />
      </Box>
    </Grid>
  );
};
