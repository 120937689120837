import { IJsonForm } from "../../models/IDetailsCertificates";
import { phase } from "./phase";

export const transformersTechnicalTest: IJsonForm[] = [
  {
    label: "Factor liquidación externo obtenido en las pruebas",
    name: "multiplier_factor",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Error del factor de liquidación externo",
    name: "multiplier_factor_error",
    type: "number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Transformadores de corriente",
    type: "title",
    name: "current_transformer",
  },
  {
    label: "Relación de transformación",
    type: "number",
    name: "current_transformer.transformation_relationship",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Fase R",
    name: "current_transformer.r_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase S",
    name: "current_transformer.s_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase T",
    name: "current_transformer.t_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Transformadores de tensión",
    type: "title",
    name: "voltage_transformer",
  },
  {
    label: "Relación de transformación",
    type: "number",
    name: "voltage_transformer.transformation_relationship",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Fase R",
    name: "voltage_transformer.r_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase S",
    name: "voltage_transformer.s_phase",
    type: "phase",
    json: phase,
  },
  {
    label: "Fase T",
    name: "voltage_transformer.t_phase",
    type: "phase",
    json: phase,
  },
];
