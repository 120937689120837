export enum EActivity {
  I = "Instalación",
  IP = "Inspección previa para cambio de comercializador a enerBit",
}

export enum EEntity {
  Lead = "LD",
  Client = "MP",
  Smartbit = "SMB",
}
