import { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const terminalsInstalled: IJsonForm[] = [
  {
    label: "Bornera",
    isSection: true,
    type: "array",
    json: [
      {
        label: "La caja/Panel es:",
        type: "select",
        name: "is_installed",
        items: [
          {
            text: "Encontrado",
            value: "false",
          },
          {
            text: "Instalado",
            value: "true",
          },
        ],
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Tipo",
        type: "select",
        name: "type_",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Elemento al que corresponde",
        type: "select",
        name: "usage",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Fabricante",
        name: "manufacturer",
        type: "text",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Sello",
        name: "seals",
        type: "seals",
        json: seals,
      },
    ],
  },
];
