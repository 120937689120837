import { IJsonForm } from "../../models/IDetailsCertificates";

export const terminalsRetired: IJsonForm[] = [
  {
    label: "Bornera",
    isSection: true,
    type: "array",
    json: [
      {
        label: "Tipo",
        type: "select",
        name: "type_",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Elemento al que corresponde",
        type: "select",
        name: "usage",
        sx: {
          xs: 12,
          md: 6,
        },
      },
    ],
  },
];
