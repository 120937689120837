/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid, Box, Typography, Tooltip, RefreshIcon } from "@enerbit/base";
import { FC } from "react";
import { EEntity } from "../../../helpers";
import { getOrders, useAppSelector } from "../../../store";
import { setSelectedOrderStatus } from "../../../store/slices/list-orders/listOrders";
import { DynamicFilter } from "../filter/DynamicFilter";
import { useAppDispatch } from "../../../store/store";
import { StyledIconButton } from "../../../common";
import { DynamicDateRange } from "../filter";

export enum EOrderType {
  Tipo1 = "Instalación",
  Tipo2 = "Normalización",
  Tipo3 = "Inspección Previa",
}

export enum EOrderStatus {
  Pendiente = "pending",
  "Parcial Completada" = "partial_done",
  Completada = "done",
  Cancelada = "cancel",
}

interface Props {
  entity: EEntity;
}

const entityDictionary: Record<EEntity, string> = {
  MP: "Cliente",
  LD: "Lead",
  SMB: "smartBit",
};

export const OrderFilter: FC<Props> = ({ entity }) => {
  const { addedEntities, entity: globalEntity } = useAppSelector(
    (state) => state.initialForm
  );
  const { selectedOrderStatus, loadingOrder } = useAppSelector(
    (state) => state.listOrders.orders[entity]
  );

  const dispatch = useAppDispatch();

  const handleRefresh = () => {
    if (addedEntities.length > 0) {
      if (EEntity.Client === entity && !addedEntities[0].id) {
        return;
      }

      dispatch(
        getOrders({
          id:
            EEntity.Client === entity && addedEntities[0].secondary_id
              ? addedEntities[0].secondary_id!
              : addedEntities[0].id,
          entity,
          dateRange: {
            startDate: "",
            endDate: "",
          },
          searchLead:
            !addedEntities[0].secondary_id &&
            globalEntity === EEntity.Client &&
            entity === EEntity.Lead,
        })
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex" alignItems="start" gap={2}>
          <Typography
            color="primary"
            sx={{ fontSize: "28px", fontWeight: 400 }}
          >
            Órdenes de {entityDictionary[entity]}
          </Typography>
          <Tooltip title="Refrescar" placement="top">
            <StyledIconButton
              disabled={loadingOrder}
              onClick={handleRefresh}
              color="primary"
              sx={{ borderRadius: "50%" }}
            >
              <RefreshIcon />
            </StyledIconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "nowrap",
          }}
        >
          {/* <DynamicFilter
            options={Object.entries(EOrderType).map(([key, value]) => ({
              key: value,
              value: key,
            }))}
            selected={selectedOrderTypes}
            onSelect={setSelectedOrderTypes}
            label="Tipo de Orden"
            isMultiple={true}
          /> */}

          <DynamicFilter
            options={Object.entries(EOrderStatus).map(([key, value]) => ({
              key,
              value,
            }))}
            selected={selectedOrderStatus}
            onSelect={(e) => {
              dispatch(setSelectedOrderStatus({ status: e, entity }));
            }}
            label="Estado de Orden"
            disabled={loadingOrder}
          />
          <DynamicDateRange entity={entity} disabled={loadingOrder} />
        </Box>
      </Box>
    </Grid>
  );
};
