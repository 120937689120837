export const orderDescriptions: Record<
	string,
	{ name: string; description: string }
> = {
	CM: {
		name: "Cambio de medidor",
		description: "",
	},
	CC: {
		name: "Cancelación de cuenta",
		description: "Cuando el cliente se quiere ir de eB",
	},
	NT: {
		name: "Normalización del sistema de comunicación",
		description:
			"Se genera cuando no se obtienen datos de las medidas de manera remota debido a fallas de comunicación.",
	},
	RSE: {
		name: "Reconexión del servicio de energía",
		description: "Se generan cuando la causa de la suspensión fue superada.",
	},
	RDCE: {
		name: "Revisión por desviación del consumo de energía",
		description:
			"Se genera por variación significativa de consumo según porcentajes establecidos en el CCU y parametrizados en el sistema. A solicitud interna del equipo de facuración",
	},
	RP: {
		name: "Revisión por PQR",
		description:
			"Se genera a solicitud del cliente por variación significativa de consumo.  Generalmente es por incremento en el consumo.",
	},
	SSSE: {
		name: "Seguimiento de la suspensión del servicio de energía",
		description:
			"Para hacer seguimiento y control en terreno de que el cliente continúa suspendido.",
	},
	SSE: {
		name: "Suspensión del servicio de energía",
		description:
			"Se generan por falta de pago, orden de autoridad competente, mutuo acuerdo o en general todas las causales que identica el CCU.",
	},
	RPSM: {
		name: "Revisión o normalización preventiva del sistema de medida",
		description: "",
	},
	CCEG: {
		name: "Cambio de comercializador a enerBit - Gabinete",
		description:
			"Dos o más clientes concentrados en un mismo gabinete que ya cuenta con una medida y desea cambiarse a enerBit como su comercializador",
	},
	NSG: {
		name: "Nuevo servicio - Gabinete",
		description:
			"Se genera para dos o más leads agrupados en un mismo gabinete que por primera vez van a tener servicio de energía y estos van a ser comercializados por enerBit",
	},
	MP: {
		name: "Modo planta",
		description: "",
	},
	AIV: {
		name: "Acompañamiento e inspección visual",
		description:
			"Realizar acompañamiento a algún agente u otra empresa. En esta actividad no se espera que el contratista ejecute ninguna actividad",
	},
	CSG: {
		name: "Cesión de servicio - Gabinete",
		description:
			"Dos o más clientes concentrados en un mismo gabinete que ya cuentan con una medida y que fueron cedidos por su comercializador actual a enerBit",
	},
	"NSCN-RPE": {
		name: "Normalización de servicio cliente notificado",
		description:
			"Si desde una revisión previa o de rutina se identificó alguna falla en el punto de medida, se notifica previamente al cliente que es necesario realizar una normalización.",
	},
	NCMP: {
		name: "Normalización por código de medida o preventiva",
		description:
			"Puede presentarse por necesidad de cambio de medidor, transformadores de medida o cualquier otro componente del sistema para cumplimiento de código de medida.",
	},
	IPP: {
		name: "Inspección previa - Proyecto",
		description:
			"Construcción de un proyecto nuevo elige a enerBit como su comercializador desde el inicio. enerBit es quien hace toda la instalación del punto de medida",
	},
	CCE: {
		name: "Cambio de comercializador a enerBit - Individual",
		description:
			"Cliente que ya cuenta con una medida y desea cambiarse a enerBit como su comercializador",
	},
	CS: {
		name: "Cesión de servicio - Individual",
		description:
			"Cliente que ya cuenta con una medida que fue cedido por su comercializador actual a enerBit",
	},
	II: {
		name: "Inspección de interventoria",
		description:
			"Órdenes de interventoría de control interno, sin compañía del OR.",
	},
	IG: {
		name: "Inspección previa - Gabinete",
		description:
			"Validación previa para evaluar el cambio de comercialización de dos o más leads agrupados en un mismo gabinete. En algunos caso es necesario acompañamiento del OR",
	},
	IPCCE: {
		name: "Inspección previa - Individual",
		description:
			"Validación previa para evaluar el cambio de comercialización. En algunos caso es necesario acompañamiento del OR",
	},
	NS: {
		name: "Nuevo servicio - Individual",
		description:
			"Se genera para un cliente que por primera vez va a tener servicio de energía y éste va a ser comercializado por enerBit.",
	},
	NSDG: {
		name: "Nuevo servicio desenergizado - Gabinete",
		description:
			"Se genera para dos o más leads agrupados en un mismo gabinete que por primera vez van a tener servicio de energía y estos van a ser comercializados por enerBit pero el proyecto aún no está energizado.",
	},
	NSDI: {
		name: "Nuevo servicio desenergizado - Individual",
		description: "",
	},
	AEMERG: {
		name: "Normalización atención de emergencias",
		description:
			"Orden generada para atender una emergencia que afecta la prestación del servicio y la causa es atribuida a los activos propiedad del cliente en los niveles de tensión 2 o 3 o su atención requiere personal, herramientas y equipos especializados. Esta orden solo se puede generar después de identificada la causa en la ejecución de una orden de Atención cliente con afectación de servicio y/o previa inspeción de energía digital.",
	},
	DSM: {
		name: "Atención cliente con afectación de servicio",
		description:
			"Orden de servicio generada por reporte de ausencia de tensión o afectación en la calidad del servicio y la causa que origina la falla es desconocida. El daño puede afectar uno o varios clientes.",
	},
	ADCSM: {
		name: "Verificación y/o actualización de datos del cliente",
		description:
			"Actualización de datos del cliente tales como, dirección, entre otros, sin requerir presencia del OR o para confirmar uso del servicio (residencial o comercial). Se puede solicitar cuando se requiera una inespección visual para confirmación o actualización de datos",
	},
	NORM_CGM_THIRD: {
		name: "Normalización CGM de terceros",
		description: "",
	},
	REMOV_CE_NO_INST: {
		name: "Cancelación de cuenta sin instalación",
		description: "Cuando el cliente se quiere ir de eB",
	},
	ISBG: {
		name: "Cambio de smartBit - Gabinete",
		description:
			"Se usa para la energización de constructoras y realizar la telemetría y validación de que la instalación quedó correctamente.",
	},
	INS_SOLAR: {
		name: "Inspección previa - SOLAR",
		description: "Validación previa para evaluar la instalación de solar.",
	},
	INST_SOLAR: {
		name: "Instalación - SOLAR",
		description: "",
	},
	TECH_RECEIPT: {
		name: "Recibo técnico",
		description:
			"Se usa para que el equipo de Fronteras Comerciales pueda verificar que los proyectos entregados por las constructoras cumplen con todos los requisitos técnicos y normativos necesarios antes de su aceptación formal. Su objetivo es registrar y validar todos los aspectos relevantes de la entrega del proyecto.",
	},
  NORM_SOLAR: {
    name: "",
    description: "",
  },
  RPQR_SOLAR: {
    name: "",
    description: "",
  },
};
