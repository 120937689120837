/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Grid,
  Dayjs,
  InputLabel,
  AdapterDayjs,
  DatePicker,
  LocalizationProvider,
  dayjs,
} from "@enerbit/base";
import { FC } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { IJsonForm } from "../../../../models/IDetailsCertificates";

export const DateForm: FC<IJsonForm> = ({ label, name, sx }) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  if (!name) return null;

  const currentValue = watch(name);

  const handleDateChange = (date: Dayjs | null) => {
    const dateStr = date && date.isValid() ? date.format("YYYY-MM-DD") : null;
    setValue(name!, dateStr);
  };

  return (
    <Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
      <InputLabel className="title-input-edition">{label}</InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name!}
          control={control}
          defaultValue={null}
          render={({ field: { value } }) => (
            <DatePicker
              key={currentValue || "empty"}
              value={value ? dayjs(value) : null}
              format="YYYY-MM-DD"
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  id: name,
                  color: "primary",
                  helperText: get(errors, name)
                    ? get(errors, name).message
                    : "",
                  error: !!get(errors, name),
                  sx: {
                    "& fieldset": { borderRadius: "14px" },
                    "& p": { color: "red" },
                    width: "100%",
                  },
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
};
