import { IJsonForm } from "../../models/IDetailsCertificates";

export const observations: IJsonForm[] = [
  {
    label: "Observaciones internas",
    type: "text-area",
    name: "for_customer",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Otras observaciones",
    name: "for_us.other",
    type: "text-area",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Mecanismo de suspensión",
    type: "text",
    name: "for_us.suspension_mechanism",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Mecanismo de suspensión",
    type: "checkbox",
    name: "for_us.suspension_mechanism",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Planta de respaldo",
    type: "text",
    name: "for_us.backup_plant",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "¿Está servicio es utilizado con algún fin comercial?",
    name: "for_us.service_used_commercial_purpose",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "¿Cuánto tiempo se estima que el cliente quedaría sin energía?",
    name: "for_us.how_long_customer_would_remain_without_power",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "¿Requiere planta eléctrica para la ejecución de las actividades?",
    name: "for_us.require_electrical_plant_execution_activities",
    type: "switch",
    sx: {
      xs: 6,
    },
  },
  {
    label: "Especificar su requerimiento",
    name: "for_us.requirements_specification",
    type: "text-area",
    sx: {
      xs: 12,
    },
  },
];
