import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
  InputLabel,
} from "@enerbit/base";
import { FC, useState } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { ISelectedEntity } from "../../../models/ICustomSelectedEntity";
import { ContactWitness } from "../contact-witness";

interface Props {
  open: boolean;
  handleClose: () => void;
  lead: ISelectedEntity;
}

export const InfoLeadModal: FC<Props> = ({ open, handleClose, lead }) => {
  const { addedEntities, selectedOrder } = useAppSelector(
    (state) => state.initialForm
  );
  const dispatch = useAppDispatch();

  const { full_name, address, department, city } = lead;

  const [tempDisabled, setTempDisabled] = useState(false);

  console.log(selectedOrder?.order_entities);
  console.log(lead);

  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xs"
      >
        <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Detalle
        </StyledDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContentText
                component="span"
                variant="h6"
                sx={{ color: "#53358E", fontWeight: "bold" }}
              >
                {full_name}
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <DialogContentText variant="body1" sx={{ color: "#344054" }}>
                <b>Dirección:</b> {address}
              </DialogContentText>
              <DialogContentText variant="body1" sx={{ color: "#344054" }}>
                <b>Departamento:</b> {department}
              </DialogContentText>
              <DialogContentText variant="body1" sx={{ color: "#344054" }}>
                <b>Ciudad:</b> {city}
              </DialogContentText>
            </Grid>
            <Grid item container xs={12}>
              <ContactWitness
                index={
                  selectedOrder?.order_entities.findIndex(
                    ({ assignee_id }) => lead.id === assignee_id
                  ) ?? 0
                }
              />
              {tempDisabled && (
                <Grid item xs={12} pt={2}>
                  <InputLabel className="title-input-edition" required>
                    Motivo
                  </InputLabel>
                  <TextField
                    fullWidth
                    sx={{ "& fieldset": { borderRadius: "14px" } }}
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {
                        !tempDisabled
                            ?
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        type="button"
                                        onClick={() => setTempDisabled(!tempDisabled)}
                                        autoFocus
                                        variant='outlined'
                                        color="primary"
                                    >
                                        Deshabilitar lead
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        onClick={handleClose}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Cerrar
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={handleClose}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Cerrar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={handleOpenDisable}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Deshabilitar
                                    </Button>
                                </Grid>
                            </Grid>
                    } */}
        </DialogActions>
      </StyledDialog>
    </>
  );
};
