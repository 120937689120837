import { api } from "@enerbit/base";
import { ILeadItem, IOperatorItem, IRespMeasurementPoint } from "../models";

export const getOperatorHistory = async (id: string) => {
  try {
    const { data } = await api.get<IOperatorItem>(
      `/ops-users/operators/${id}/`
    );
    return {
      id,
      name: data.pii.full_name,
      email: data.username,
    };
  } catch (error) {
    return null;
  }
};

export const getOperatorsHistory = async (ids: string[]) => {
  const operators = await Promise.all(
    ids.map(async (id) => {
      try {
        const { data } = await api.get<IOperatorItem>(
          `/ops-users/operators/${id}/`
        );
        return {
          id,
          name: data.pii.full_name,
          email: data.username,
        };
      } catch (error) {
        return null;
      }
    })
  );

  return operators.filter((operator) => operator !== null) as {
    id: string;
    name: string;
    email: string;
  }[];
};

export const getLeadsHistory = async (ids: string[]) => {
  const leads = await Promise.all(
    ids.map(async (id) => {
      try {
        const { data } = await api.get<ILeadItem>(`assignees/leads/${id}`);
        return {
          id,
          name: data.display_name,
          email: data.email_from,
        };
      } catch (error) {
        return null;
      }
    })
  );

  return leads.filter((lead) => lead !== null) as {
    id: string;
    name: string;
    email: string;
  }[];
};

export const getLeadHistory = async (id: string) => {
  try {
    const { data } = await api.get<ILeadItem>(`assignees/leads/${id}`);
    return {
      id,
      name: data.display_name,
      email: data.email_from,
    };
  } catch (error) {
    return null;
  }
};
