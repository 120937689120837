import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  enerbitColors,
  Typography,
  theme,
  Grid,
  styled,
  KeyboardArrowRightIcon,
} from "@enerbit/base";
import { useState, SyntheticEvent, FC } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { IJsonForm } from "../../../../models/IDetailsCertificates";
import { EmptyData } from "../../empty-data";
import { FormItem } from "../FormItem";

const Accordion = styled(MuiAccordion)`
  border-radius: 16px !important;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 56px;
  border-radius: 16px !important;
  &.Mui-expanded {
    min-height: 56px;
    border-radius: 16px 16px 0px 0px !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 16px;
`;

export const ArrayForm: FC<IJsonForm> = ({ name, label, json }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: name ?? "",
  });

  const [expanded, setExpanded] = useState<number | null>(0);

  const handleChange =
    (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : null);
    };

  return (
    <Grid container item xs={12} spacing={3}>
      {name &&
        fields.map((item, index: number) => (
          <Grid key={index} item xs={12}>
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              sx={{
                border: `1px solid ${enerbitColors.primary.main}`,
              }}
            >
              <AccordionSummary
                sx={{
                  background: enerbitColors.primary.main,
                  padding: theme.spacing(1, 2),
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    color: "white",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
                expandIcon={
                  <KeyboardArrowRightIcon sx={{ fontSize: "1rem" }} />
                }
              >
                <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                  {label} # {index + 1}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: theme.spacing(3),
                  borderTop: "1px solid rgba(0, 0, 0, .125)",
                }}
              >
                <Grid container spacing={3}>
                  {json &&
                    json?.map((item, i) => {
                      const currentField = {
                        ...item,
                        name: `${name}.${index}.${item.name}`,
                      };
                      return <FormItem field={currentField} key={i} />;
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      {fields.length === 0 && (
        <Grid item xs={12}>
          <EmptyData message="Aún no se ha agregado nada." />
        </Grid>
      )}
    </Grid>
  );
};
