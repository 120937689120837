/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDateRange, IGetOrders, IResponseLeads } from "../../../models";
import { EEntity } from "../../../helpers/common";
import { getRangeISO } from "../../../helpers";

export const getOrders = createAsyncThunk(
  "listOrders/getOrders",
  async (
    {
      id,
      entity,
      page = 0,
      size = 9,
      dateRange,
      status,
      searchLead,
    }: {
      id: string;
      entity: EEntity;
      page?: number;
      size?: number;
      dateRange: IDateRange;
      status?: string;
      searchLead: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      let currentID = id;
      if (searchLead) {
        const responseLeads = await api.get<IResponseLeads>(
          `/assignees/leads?measurement_point_id=${id}`
        );
        currentID = responseLeads.data.items[0].id;
      }

      const { start, end } = getRangeISO(dateRange);
      const { data } = await api.get<IGetOrders>(
        `/service-orders/orders?sort_planned_date_begin=DESC&assignee_id=${currentID}${
          entity ? `&entity_code=${entity}` : ""
        }${status ? `&status=${status}` : ""}&page=${page}&size=${size}${
          dateRange.startDate ? `&planned_date_begin=${start}` : ""
        }${dateRange.endDate ? `&planned_date_end=${end}` : ""}`
      );
      return {
        orders: data.items.sort((a, b) => {
          const dateA = new Date(a.planned_date_begin);
          const dateB = new Date(b.planned_date_begin);

          return dateB.getTime() - dateA.getTime();
        }),
        totalOrders: data.total,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.status);
    }
  }
);
