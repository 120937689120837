/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@enerbit/base";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { IJsonForm } from "../../../../models/IDetailsCertificates";
import { MatrixItem } from "./MatrixItem";

export const MatrixForm: FC<IJsonForm> = ({ name }) => {
  const { watch } = useFormContext();

  const matrix = watch(name ?? "");

  return (
    <>
      {matrix.map((row: any[], rowIndex: number) => (
        <Grid container item xs={12} key={rowIndex} spacing={3}>
          {row.map((_, colIndex: number) => {
            const fieldName = `${name}.${rowIndex}.${colIndex}`;
            return (
              <MatrixItem key={colIndex} name={fieldName} row={row.length} />
            );
          })}
        </Grid>
      ))}
    </>
  );
};
