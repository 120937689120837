import { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const optionalMeterRetired: IJsonForm[] = [
  {
    name: "meter_serial.value",
    label: "Serie medidor",
    type: "number",
    sx: {
      xs: 12,
    },
  },
  {
    name: "meter_serial.picture_url",
    label: "Foto de Serie medidor",
    type: "image",
  },
  {
    name: "usage",
    label: "Función del medidor",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "ownership",
    label: "Propiedad del medidor",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "meter_brand",
    label: "Marca medidor",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "measurement_type",
    type: "select",
    label: "Tipo de medición",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "active_energy_imported.value",
    label: "Lectura importación activa",
    type: "number",
    sx: {
      xs: 12,
    },
  },
  {
    name: "active_energy_imported.picture_url",
    label: "Lectura importación activa",
    type: "image",
  },
  {
    name: "active_energy_exported.value",
    label: "Lectura exportación activa",
    type: "number",
    sx: {
      xs: 12,
    },
  },
  {
    name: "active_energy_exported.picture_url",
    label: "Lectura exportación activa",
    type: "image",
  },
  {
    name: "reactive_energy_imported.value",
    label: "Lectura importación reactiva",
    type: "number",
    sx: {
      xs: 12,
    },
  },
  {
    name: "reactive_energy_imported.picture_url",
    label: "Lectura importación reactiva",
    type: "image",
  },
  {
    name: "reactive_energy_exported.value",
    label: "Lectura exportación reactiva",
    type: "number",
    sx: {
      xs: 12,
    },
  },
  {
    name: "reactive_energy_exported.picture_url",
    label: "Lectura exportación reactiva",
    type: "image",
  },
  {
    label: "Sello",
    name: "seals",
    type: "seals",
    json: seals,
  },
];
