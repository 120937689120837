/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const cleanObjectStepper = (obj: any, isChild = false) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      (!isChild &&
        (typeof obj[propName] === "string" ||
          typeof obj[propName] === "boolean"))
    ) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      if (Array.isArray(obj[propName])) {
        obj[propName].forEach((item: any) => {
          cleanObjectStepper(item, true);
        });
      } else {
        cleanObjectStepper(obj[propName], true);
      }
    }
  }
  return obj;
};

export const cleanObject = (obj: any) => {
  for (const propName in obj) {
    if (obj[propName] === null) {
      delete obj[propName];
    } else if (typeof obj[propName] === "object") {
      if (Array.isArray(obj[propName])) {
        obj[propName].forEach((item: any) => {
          cleanObject(item);
        });
      } else {
        cleanObject(obj[propName]);
      }
    }
  }
  return obj;
};

const countSectionsAndCompleted = (data: any): [number, number] => {
  let total = 0;
  let completed = 0;

  const checkSection = (item: any) => {
    if (item.hasOwnProperty("isCompletedSection")) {
      completed++;
    }
  };

  for (const key in data) {
    if (key !== "summary") {
      if (Array.isArray(data[key])) {
        total += data[key].length;
        data[key].forEach(checkSection);
      } else {
        total++;
        checkSection(data[key]);
      }
    }
  }

  return [total, completed];
};

export const getPercentComplete = (objeto: any): number => {
  if (Object.keys(objeto).length === 0) return 0;
  const [total, completed] = countSectionsAndCompleted(objeto);
  const completionPercentage = (completed / total) * 100;

  return Math.ceil(completionPercentage);
};
