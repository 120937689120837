import {
  Grid,
  FormControlLabel,
  Switch,
  InputLabel,
  HelpOutlineIcon,
  enerbitColors,
} from "@enerbit/base";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IJsonForm } from "../../../../models/IDetailsCertificates";

export const SwitchForm: FC<IJsonForm> = ({ name, label, sx, isIcon }) => {
  const { watch } = useFormContext();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fieldValue = !!watch(name ?? "");
    setChecked(fieldValue);
  }, [watch(name ?? "")]);

  return (
    <Grid item xs={sx?.xs}>
      <InputLabel
        
        sx={{
          display: "flex",
          alignItems: "end",
          color: enerbitColors.primary.main,
          fontWeight:'bold'
        }}
      >
        {isIcon ? <HelpOutlineIcon fontSize="small" /> : <></>} {label}
      </InputLabel>
      <FormControlLabel
        control={<Switch color="secondary" checked={checked} readOnly />}
        label={checked ? "Si" : "No"}
      />
    </Grid>
  );
};
