/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Container,
  Grid,
  NavSidebar,
  Typography,
  motion,
  AnimatePresence,
} from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../store/store";
import { FilterProvider } from "./components/filter/FilterProvider";
import { ResultsFilter, ResultsFilterSkeleton } from "./components/filter";
import { OrderContainer, OrderContainerSkeleton } from "./components/order";
import { EEntity } from "../helpers/common";
import { CreateOrder } from "./components/create-order";
import "./Orders.css";
import { ConsultOrder } from "./components/consult-order/ConsultOrder";
import { EditOrder } from "./components/edit-order/EditOrder";
import { CancelOrder } from "./components/cancel-order/CancelOrder";
import { useEffect, useRef } from "react";
import { Certifications } from "./components/certifications/Certifications";
import { enqueueSnackbar } from "notistack";
import { resetActivity } from "../store/slices/initial-form/initialForm";
import { resetPages } from "../store/slices/list-orders/listOrders";
import { getRole } from "../store/slices/roles/roles";
import { resetAddedOperators } from "../store/slices/team-operators/teamOperators";

export const Orders = () => {
  const {
    addedEntities,
    entity,
    action,
    loadingFrontier,
    loadingSmartbit,
    loadingLeads,
    errorOrder,
    message,
  } = useAppSelector((state) => state.initialForm);
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
  }, [action]);

  useEffect(() => {
    if (errorOrder) {
      enqueueSnackbar(message, { variant: "error" });
    }
  }, [errorOrder]);

  useEffect(() => {
    dispatch(resetAddedOperators());
  }, [action]);

  useEffect(() => {
    if (addedEntities.length > 0) {
      dispatch(resetActivity());
      dispatch(resetPages());
    }
  }, [addedEntities[0]]);

  useEffect(() => {
    dispatch(getRole());
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <NavSidebar />
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          flexGrow: 1,
          paddingTop: "70px",
          overflow: "auto",
        }}
      >
        <Container sx={{ py: 4 }} maxWidth={"xl"}>
          <AnimatePresence mode="wait">
            <motion.div
              key={action ? "withAction" : "withoutAction"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {!action ? (
                <>
                  <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
                  >
                    Consulta
                  </Typography>
                  <Grid container spacing={3}>
                    <FilterProvider />
                    {!(loadingFrontier || loadingSmartbit || loadingLeads) &&
                      addedEntities.length > 0 && (
                        <>
                          <ResultsFilter />
                          {(entity === EEntity.Lead ||
                            entity === EEntity.Client) && (
                            <>
                              <OrderContainer entity={EEntity.Client} />
                              <OrderContainer entity={EEntity.Lead} />
                            </>
                          )}
                          {entity === EEntity.Smartbit && (
                            <OrderContainer entity={EEntity.Smartbit} />
                          )}
                        </>
                      )}
                    {(loadingFrontier || loadingSmartbit || loadingLeads) && (
                      <>
                        <ResultsFilterSkeleton />
                        <OrderContainerSkeleton />
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  {action.type === "Create" && <CreateOrder />}
                  {action.type === "Consult" && <ConsultOrder />}
                  {action.type === "Edit" && <EditOrder />}
                  {action.type === "Cancel" && <CancelOrder />}
                  {action.type === "Certifications" && <Certifications />}
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </Container>
      </Box>
    </div>
  );
};
