import { enerbitColors } from "@enerbit/base";
import { FC } from "react";
import { StyledChip } from "../../../common";

interface Props {
  event: "created" | "modified" | "removed";
  status?: boolean;
}

export const StatusHistory: FC<Props> = ({ event, status }) => {
  return (
    <>
      {event === "created" && (
        <StyledChip
          label={!status ? "Creado" : "Agregado"}
          sx={{
            background: "#EFE9FB",
            color: enerbitColors.primary.main,
          }}
        />
      )}
      {event === "modified" && (
        <StyledChip
          label="Editado"
          sx={{
            background: "#FFEDDC",
            color: enerbitColors.secondary.orange.main,
          }}
        />
      )}
      {event === "removed" && (
        <StyledChip
          label="Eliminado"
          sx={{
            background: "#FEECEB",
            color: enerbitColors.error.main,
          }}
        />
      )}
    </>
  );
};
