import { IJsonForm } from "../../models/IDetailsCertificates";

export const requestTechnicalSupport: IJsonForm[] = [
  {
    name: "requested",
    label: "¿El subscriptor hace uso de su derecho?",
    type: "switch",
    sx: {
      xs: 6,
    },
  },
  {
    name: "minutes",
    label: "Tiempo máximo para hacerse presente en el sitio",
    type: "number",
    sx: {
      xs: 6,
    },
  },
];
