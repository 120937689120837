import { Grid, TextField } from "@enerbit/base";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormDetailsModal } from "../../modals/FormDetailsModal";

interface Props {
  name: string;
  row: number;
}

export const MatrixItem: FC<Props> = ({ name, row }) => {
  const { watch, register } = useFormContext();
  const fieldValue = watch(`${name}.main_meter_installed.name`);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (fieldValue) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={Math.floor(12 / row)}>
        <TextField
          fullWidth
          {...register(`${name}.main_meter_installed.name`)}
          onClick={handleClickOpen}
          sx={{ "& fieldset": { borderRadius: "14px" } }}
          variant="outlined"
          inputProps={{
            readOnly: true,
            style: { textAlign: "center" },
          }}
          value={fieldValue && fieldValue.length > 0 ? fieldValue : "-"}
        />
      </Grid>
      {open && (
        <FormDetailsModal open={open} handleClose={handleClose} name={name} />
      )}
    </>
  );
};
