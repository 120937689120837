/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogContent, Grid } from "@enerbit/base";
import { FC, useEffect, useState } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { useFormContext } from "react-hook-form";
import { AccordionItem, FormItem } from "../stepper";
import { stepperList } from "../../../const/stepper.config";
import { IStepperList } from "../../../models/IDetailsCertificates";
import { aditionalInfo } from "../../../const/json";

interface Props {
  open: boolean;
  handleClose: () => void;
  name: string;
}

export const FormDetailsModal: FC<Props> = ({ open, handleClose, name }) => {
  const { watch } = useFormContext();
  const [info, setInfo] = useState(aditionalInfo);
  const [filteredKeysStepper, setFilteredKeysStepper] = useState<
    IStepperList[]
  >([]);
  const [expanded, setExpanded] = useState<number | false>(-1);

  const handleChange = (index: number) => {
    setExpanded((prev) => (prev === index ? -1 : index));
  };

  const hasNestedKey: any = (obj: any, keyPath: any) => {
    const keys = Array.isArray(keyPath) ? keyPath : keyPath.split(".");
    if (!keys.length) return true;
    if (!obj || typeof obj !== "object") return false;
    const key = keys[0];
    if (!(key in obj)) return false;
    return hasNestedKey(obj[key], keys.slice(1));
  };

  const watchValue = watch(name);

  useEffect(() => {
    const newStepperList = stepperList
      .filter(({ sectionName }) => hasNestedKey(watchValue, sectionName))
      .map((item) => ({ ...item, sectionName: `${name}.${item.sectionName}` }));
    setFilteredKeysStepper(newStepperList);
  }, [watchValue]);

  useEffect(() => {
    setInfo((prev) => {
      const newInfo = [...prev];
      const newItems = watch("leadsJSON.leads").map(
        ({ full_name, frontier_id }: any) => ({
          text: full_name,
          value: frontier_id,
        })
      );
      newInfo[1].items = newItems;
      return newInfo;
    });
  }, [watch("leadsJSON.leads")]);

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Detalle
      </StyledDialogTitle>
      <DialogContent>
        {filteredKeysStepper.length > 0 && (
          <Grid container spacing={3}>
            {info.map((field, index) => {
              const newField = { ...field, name: `${name}.${field.name}` };
              return <FormItem field={newField} key={index} />;
            })}
            {filteredKeysStepper.map((step, index) => {
              const newStep: IStepperList = JSON.parse(JSON.stringify(step));

              if (newStep.data) {
                newStep.data.forEach((item) => {
                  item.name = item.isSection
                    ? `${newStep.sectionName}`
                    : `${newStep.sectionName}.${item.name}`;
                });
              }

              return (
                <AccordionItem
                  key={index}
                  index={index}
                  step={newStep}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              );
            })}
          </Grid>
        )}
      </DialogContent>
    </StyledDialog>
  );
};
