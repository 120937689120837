import { Grid, Typography } from "@enerbit/base";
import { SearchOrder } from "./SearchOrder";

export const FilterProvider = () => {
  return (
    <Grid container spacing={2} item>
      <Grid item xs={12}>
        <Typography>Consulta por Correo, smartBit, FRT y Lead ID</Typography>
      </Grid>
      <SearchOrder />
    </Grid>
  );
};
