import { IJsonForm } from "../../models/IDetailsCertificates";

export const seals: IJsonForm[] = [
  {
    type: "text",
    label: "Serie del sello",
    name: "serial_number",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "text",
    label: "Serie del sello",
    name: "serial_number.value",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "select",
    label: "Ubicación del sello",
    name: "location",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "select",
    label: "Tipo del sello",
    name: "type_",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "text",
    label: "Color del sello",
    name: "color",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "select",
    label: "Propiedad del sello",
    name: "ownership",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "select",
    label: "Movimiento del sello de seguridad",
    name: "action",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    type: "image-seal",
    label: "Foto de la serie del sello",
    name: "serial_number.picture_url",
  },
];
