import {
  EditIcon,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@enerbit/base";
import { HistoryDetails, StatusHistory } from ".";
import { formatCustomDate, formatCustomTime } from "../../../helpers";
import { getHistory, useAppDispatch, useAppSelector } from "../../../store";
import { useCallback, useRef, useState } from "react";
import { enerbitColors } from "@enerbit/base";
import InfiniteScroll from "react-infinite-scroller";

export const HistoryOrder: React.FC = () => {
  const [hasMore, setHasMore] = useState(true);
  const scrollParentRef = useRef<HTMLDivElement | null>(null);

  const { selectedOrder } = useAppSelector((state) => state.initialForm);

  const { history, loadingHistory, totalHistory } = useAppSelector(
    (state) => state.history
  );
  const dispatch = useAppDispatch();

  const fetchHistory = async (page: number) => {
    try {
      await dispatch(getHistory({ id: selectedOrder?.id ?? "", page }));
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const loadMore = useCallback(
    async (page: number) => {
      if (page <= totalHistory) {
        await fetchHistory(page);
      } else {
        setHasMore(false);
      }
    },
    [loadingHistory, totalHistory]
  );

  return (
    <div style={{ height: "60vh", overflow: "auto" }} ref={scrollParentRef}>
      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={(page) => loadMore(page)}
          hasMore={hasMore && !loadingHistory}
          loader={
            <div key={0} style={{ textAlign: "center", padding: "20px" }}>
              Cargando más elementos...
            </div>
          }
          useWindow={false}
          getScrollParent={() => scrollParentRef.current}
        >
          <Timeline
            sx={{
              [`& .MuiTimelineOppositeContent-root`]: {
                flex: 0.2,
              },
            }}
          >
            {history.map(
              (
                { event, event_on, responsible, created_at, change_data },
                index
              ) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent color="text.secondary">
                    {formatCustomDate(created_at)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      variant="outlined"
                      color={
                        event === "created"
                          ? "primary"
                          : event === "modified"
                          ? "warning"
                          : "error"
                      }
                    />
                    {index !== history.length - 1 && (
                      <TimelineConnector
                        sx={{
                          backgroundColor:
                            event === "created"
                              ? enerbitColors.primary[300]
                              : event === "modified"
                              ? enerbitColors.secondary.orange[300]
                              : enerbitColors.error[300],
                        }}
                      />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      variant="outlined"
                      sx={{ p: 4, borderRadius: "14px" }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <StatusHistory
                            event={event}
                            status={event_on !== "orders"}
                          />
                        </Grid>
                        {change_data && change_data.body && (
                          <Grid item xs={12}>
                            <HistoryDetails
                              data={change_data.body}
                              event_on={event_on}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <ListItem color={"#858D9D"}>
                            <ListItemIcon sx={{ minWidth: "28px" }}>
                              <EditIcon sx={{ fontSize: "15px" }} />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: "#858D9D" }}
                              primary={` ${
                                responsible.names
                              } - ${formatCustomTime(created_at)}`}
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              )
            )}
          </Timeline>
        </InfiniteScroll>
      </div>
    </div>
  );
};
