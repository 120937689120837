import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import {
  tableAddress,
  initialForm,
  detailProgress,
  history,
  listOrders,
  roles,
  teamOperators,
} from "./slices";

export const store = configureStore({
  reducer: {
    tableAddress: tableAddress,
    initialForm: initialForm,
    detailProgress: detailProgress,
    history: history,
    listOrders: listOrders,
    roles: roles,
    teamOperators: teamOperators,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
