import {
  Typography,
  Container,
  ArrowCircleLeftOutlinedIcon,
  Grid,
  IconButton,
} from "@enerbit/base";
import { useAppSelector } from "../../../store";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { CancelProvider } from "../form-provider";
import { LoadingOrder } from "../loading";
import { useAppDispatch } from "../../../store/store";

export const CancelOrder = () => {
  const { selectedOrder, loadingOrder } = useAppSelector(
    (state) => state.initialForm
  );
  const dispatch = useAppDispatch();

  return (
    <Container sx={{ py: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <IconButton
            color="primary"
            size="small"
            onClick={() => dispatch(setAction(null))}
          >
            <ArrowCircleLeftOutlinedIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10} display="flex" alignItems={"center"}>
          <Typography
            color="primary"
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              mb: "12px",
              display: "flex",
              flex: 1,
            }}
          >
            Cancelar orden de servicio
          </Typography>
        </Grid>
      </Grid>
      {selectedOrder && !loadingOrder && <CancelProvider />}
      {loadingOrder && <LoadingOrder />}
    </Container>
  );
};
