import { Pagination } from "@enerbit/base";
import { EEntity } from "../helpers";
import { useAppSelector, useAppDispatch } from "../store";
import { setPage } from "../store/slices/table-address/tableAddress";

export const CustomPagination = () => {

    const { rowCount, page, loading } = useAppSelector(state => state.tableAddress);
    const { entity } = useAppSelector(state => state.initialForm);
    const dispatch = useAppDispatch();

    return (
        <Pagination
            disabled={loading}
            shape="rounded"
            color="primary"
            count={rowCount}
            page={entity === EEntity.Client ? page + 1 : page}
            onChange={(_, value) => dispatch(setPage(entity === EEntity.Client ? value - 1 : value))}
        />
    );
};