import { IJsonForm } from "../../models/IDetailsCertificates";

export const materialsToExecute: IJsonForm[] = [
  {
    label: "Material",
    isSection: true,
    type: "array",
    json: [
      {
        label: "Descripción del material utilizado",
        name: "name",
        type: "select",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Cantidad",
        name: "quantity",
        type: "number",
        sx: {
          xs: 12,
          md: 6,
        },
      },
      {
        label: "Unidad de medida de material utilizado",
        name: "unit",
        type: "select",
        sx: {
          xs: 12,
          md: 6,
        },
      },
    ],
  },
];
