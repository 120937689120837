import { Chip, styled } from "@enerbit/base";

export const StyledChip = styled(Chip)(() => ({
  borderRadius: "8px",
  "& .MuiChip-icon ": {
    color: "inherit",
    alignSelf: "center",
  },
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "start",
    flexWrap: "wrap",
    whiteSpace: "normal",
  },
}));
