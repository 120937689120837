import { useAppDispatch } from "../store";
import { resetDetailProgress } from "../store/slices/detail-progress/detailProgress";
import {
  resetInitialForm,
  resetPartialForm,
} from "../store/slices/initial-form/initialForm";
import { resetTableAddress } from "../store/slices/table-address/tableAddress";

export const useResetStore = () => {
  const dispatch = useAppDispatch();

  const resetForm = () => {
    dispatch(resetInitialForm());
    resetAll();
  };

  const resetFormContent = () => {
    dispatch(resetPartialForm());
    resetContent();
  };

  const resetAll = () => {
    dispatch(resetInitialForm());
    dispatch(resetTableAddress());
    dispatch(resetDetailProgress());
  };

  const resetContent = () => {
    dispatch(resetTableAddress());
  };

  return {
    resetForm,
    resetFormContent,
  };
};
