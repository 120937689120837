import { IJsonForm } from "../../models/IDetailsCertificates";

export const generalData: IJsonForm[] = [
  {
    label: "Mapa",
    type: "geolocation",
    name: "geolocalization",
  },
  {
    label: "Número de transformador de potencia",
    name: "power_transformer_serial_number",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    label: "Tensión del servicio (kV)",
    name: "voltage_level",
    type: "select",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_capacity_contracted.value",
    type: "number",
    label: "Capacidad instalada (kVA)",
    sx: {
      xs: 12,
    },
  },
  {
    name: "power_capacity_contracted.picture_url",
    type: "image",
    label: "Foto de la capacidad [KVA] del transformador de potencia",
  },
  {
    name: "power_transformer_primary_voltage",
    type: "number",
    label: "Tensión primaria del transformador de potencia (kV)",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_transformer_secundary_voltage",
    type: "number",
    label: "Tensión secundaria del transformador de potencia (V)",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_transformer_phase_number",
    type: "select",
    label: "Número de fases del transformador de potencia",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_transformer_usage",
    type: "select",
    label: "Uso del transformador de potencia",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_transformer_node",
    type: "text",
    label: "Nodo transformador de potencia",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "power_transformer_location",
    type: "select",
    label: "Ubicación transformador de potencia",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "connection",
    type: "select",
    label: "Conexión",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "multiplier_factor",
    type: "number",
    label: "Factor de liquidación externo",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "capacitor_bank_capacity",
    type: "text",
    label: "Banco de condensadores (kVaR)",
    sx: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: "measurement_system_picture.picture_url",
    label: "Foto del sistema de medida",
    type: "image",
  },
  {
    name: "power_transformer_feature_plate_picture.picture_url",
    label: "Foto de la placa de características del transformador de potencia",
    type: "image",
  },
];
