/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, enerbitColors, Grid } from "@enerbit/base";
import { useRef } from "react";
import ReactFlow, { ReactFlowProvider, Controls } from "reactflow";
import { MeterNode } from "./MeterNode";
import { SmartbitNode } from "./SmartbitNode";
import { useAppSelector } from "../../../store/store";
import "reactflow/dist/style.css";

export const SmartbitFlow = () => {
  const reactFlowWrapper = useRef(null);

  const { nodes, edges } = useAppSelector((state) => state.initialForm);

  return (
    <Grid xs={12} item>
      <ReactFlowProvider>
        <Box
          sx={{
            width: "100%",
            height: "450px",
            border: `2px solid ${enerbitColors.primary.main}`,
            borderRadius: "16px",
            backgroundColor: "#F5F5F5",
          }}
          ref={reactFlowWrapper}
        >
          <ReactFlow
            zoomOnScroll={false}
            zoomOnPinch={false}
            nodes={nodes}
            edges={edges}
            nodeTypes={{
              smartBit: SmartbitNode,
              meter: MeterNode,
            }}
            fitView
          >
            <Controls />
          </ReactFlow>
        </Box>
      </ReactFlowProvider>
    </Grid>
  );
};
