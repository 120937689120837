import { Grid, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { OperatorItem } from ".";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { EmptyData } from "../empty-data";
import { loadOperators } from "../../../store/slices/team-operators/teamOperators";

export const OperatorContainer = () => {
  const {
    formState: { errors },
  } = useFormContext();
  const { setValue, watch } = useFormContext();
  const { action } = useAppSelector((state) => state.initialForm);
  const { addedOperators } = useAppSelector((state) => state.teamOperators);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (action?.type === "Create") {
      setValue(
        "order_operators",
        addedOperators.map(({ operator_id }) => ({ operator_id }))
      );
    }
  }, [addedOperators]);

  useEffect(() => {
    if (action?.type !== "Create") {
      dispatch(loadOperators(watch("order_operators")));
    }
  }, [watch("order_operators")]);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          color="primary"
          sx={{ fontSize: "22px", fontWeight: 400, mb: "12px" }}
        >
          Operadores
        </Typography>
      </Grid>
      {addedOperators?.length > 0 ? (
        <>
          {addedOperators.map((operator, index) => (
            <OperatorItem key={index} operator={operator} index={index} />
          ))}
        </>
      ) : (
        <Grid item xs={12}>
          <EmptyData message="Aún no has agregado un operador a esta orden, por favor agrega al menos uno." />
          {errors.order_operators && (
            <Typography color="error" variant="body2">
              {errors.order_operators.message}
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};
