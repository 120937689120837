import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@enerbit/base";
import { FC } from "react";
import { useFormContext, get, Controller } from "react-hook-form";
import { IItemsJson, IJsonForm } from "../../../../models";

export const CheckboxForm: FC<IJsonForm> = ({ name, label, sx }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  if (!name) return null;

  const isIItemsJson = (item: any): item is IItemsJson => {
    return (
      (item as IItemsJson).value !== undefined &&
      (item as IItemsJson).text !== undefined
    );
  };

  if (!name) return null;

  return (
    <Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
      <FormControl
        error={!!get(errors, name)}
        component="fieldset"
        variant="standard"
      >
        <FormLabel sx={{ mb: 1 }} component="legend">
          {label}
        </FormLabel>
        <FormGroup>
          {watch(name)?.map((item: any, index: number) => {
            const itemValue = isIItemsJson(item) ? item.value : item;
            const itemLabel = isIItemsJson(item) ? item.text : String(item);
            return (
              <FormControlLabel
                key={index}
                control={
                  <Controller
                    name={name}
                    control={control}
                    disabled
                    render={({ field }) => (
                      <Checkbox
                        readOnly
                        {...field}
                        id={`${name}-${index}`}
                        checked={
                          field.value ? field.value.includes(itemValue) : false
                        }
                        onChange={(e) => {
                          const newValue = field.value ? [...field.value] : [];
                          if (e.target.checked) {
                            newValue.push(itemValue);
                          } else {
                            const idx = newValue.indexOf(itemValue);
                            if (idx >= 0) {
                              newValue.splice(idx, 1);
                            }
                          }
                          field.onChange(newValue);
                        }}
                      />
                    )}
                  />
                }
                label={itemLabel}
              />
            );
          })}
        </FormGroup>
        {!!get(errors, name) && (
          <FormHelperText error>{get(errors, name).message}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};
