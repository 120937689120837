import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
} from "@enerbit/base";
import { Controller, get, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store";

export const InfoCancellation = () => {
  const { cancellationReasons } = useAppSelector((state) => state.initialForm);
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          color="primary"
          sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
        >
          Información de la cancelación
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputLabel className="title-input-edition" required>
          Motivo de cancelación
        </InputLabel>
        <FormControl fullWidth>
          <Controller
            name="cancellation_reason_id"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  sx={{ borderRadius: "14px", background: "white" }}
                  variant="outlined"
                  fullWidth
                  error={!!get(errors, "cancellation_reason_id")}
                >
                  {cancellationReasons.map(({ name, id }, index) => (
                    <MenuItem key={index} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {errors.cancellation_reason_id &&
                    errors.cancellation_reason_id.message}
                </FormHelperText>
              </>
            )}
            defaultValue=""
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <InputLabel className="title-input-edition" required>
          Observaciones
        </InputLabel>
        <TextField
          fullWidth
          id="observations"
          type="text"
          sx={{ "& fieldset": { borderRadius: "14px" } }}
          {...register("observations")}
          error={!!get(errors, "observations")}
          helperText={
            !!get(errors, "observations") && get(errors, "observations").message
          }
          variant="outlined"
          rows={4}
          multiline
        />
      </Grid>
    </Grid>
  );
};
