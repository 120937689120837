import {
  Typography,
  Container,
  Box,
  ArrowCircleLeftOutlinedIcon,
  Grid,
  IconButton,
} from "@enerbit/base";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { EditProvider } from "../form-provider/EditProvider";
import { LoadingOrder } from "../loading";
import { useEffect } from "react";
import { getEntities } from "../../../store";

export const EditOrder = () => {
  const { selectedOrder, loadingOrder } = useAppSelector(
    (state) => state.initialForm
  );
  const { open } = useAppSelector((state) => state.tableAddress);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntities());
  }, []);

  return (
    <Container sx={{ py: 5 }}>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => dispatch(setAction(null))}
            >
              <ArrowCircleLeftOutlinedIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={10} display="flex" alignItems={"center"}>
            <Typography
              color="primary"
              sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
            >
              Editar orden de servicio
            </Typography>
          </Grid>
        </Grid>
        {selectedOrder && !loadingOrder && !open && <EditProvider />}
        {loadingOrder && <LoadingOrder />}
      </Box>
    </Container>
  );
};
