import { Grid, Box, Skeleton } from "@enerbit/base";

export const OrderFilterSkeleton = () => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Skeleton variant="text" width={210} height={40} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "nowrap",
          }}
        >
          <Skeleton variant="rounded" width={100} height={30} />
          <Skeleton variant="rounded" width={100} height={30} />
        </Box>
      </Box>
    </Grid>
  );
};
