import { IJsonForm } from "../../models/IDetailsCertificates";

export const activities: IJsonForm[] = [
  {
    label: "Actividad",
    isSection: true,
    type: "array",
    json: [
      {
        label: "Actividad a ejecutar",
        name: "name",
        type: "select",
        sx: {
          xs: 12,
          md: 6,
        },
      },
    ],
  },
];
