import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decodedJwt } from "@enerbit/base";
import { IRolesState, RoleCode } from "../../../models/IRoles";
import { EEntity } from "../../../helpers";
import { definePermissions } from "../../../helpers/roles";

const initialState: IRolesState = {
  role: "",
  canCreateOrders: false,
};

const roles = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getRole: (state) => {
      const decodedToken = decodedJwt();
      if (decodedToken) {
        state.role = decodedToken.role;
      }
    },
    createOrderPermissions: (
      state,
      { payload }: PayloadAction<{ role: RoleCode; entity: EEntity | null }>
    ) => {
      state.canCreateOrders = definePermissions(payload.role, payload.entity);
    },
  },
});

export const { getRole, createOrderPermissions } = roles.actions;

export default roles.reducer;
