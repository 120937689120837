import {
  Grid,
  Box,
  Typography,
  IconButton,
  DeleteOutlineIcon,
} from "@enerbit/base";
import { FC, useState } from "react";
import { OrderOperatorGetOrders } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { DeleteItemModalId } from "../modals";
import { deleteOrderOperators } from "../../../store";
import { handleDeleteOperator } from "../../../store/slices/team-operators/teamOperators";

interface Props {
  operator: OrderOperatorGetOrders;
  index: number;
}

export const OperatorItem: FC<Props> = ({ operator, index }) => {
  const { pii } = operator;
  const { action } = useAppSelector((state) => state.initialForm);
  const { addedOperators } = useAppSelector((state) => state.teamOperators);
  const dispatch = useAppDispatch();

  const [openDeleteOperator, setOpenDeleteOperator] = useState(false);
  const handleOpenDeleteOperator = () => setOpenDeleteOperator(true);
  const handleCloseDeletePartner = () => setOpenDeleteOperator(false);
  const deleteOperator = () => {
    if (action?.type === "Create") {
      dispatch(handleDeleteOperator(operator.operator_id || ""));
    } else {
      dispatch(
        deleteOrderOperators({
          id: operator.id || "",
          operator_id: operator.operator_id,
        })
      );
    }
  };

  return (
    <Grid item xs={12}>
      <Box className={"box-certification-edit"} p={4}>
        <Box display="flex" justifyContent="space-between" width={"100%"}>
          <Box sx={{ display: "flex", alignItems: "center" }} width={"100%"}>
            {pii && (
              <Typography
                component="span"
                variant="h6"
                sx={{ color: "#53358E", fontWeight: "bold" }}
              >
                {pii.full_name}
              </Typography>
            )}
          </Box>
          {action?.type !== "Consult" && addedOperators.length > 1 && (
            <>
              <IconButton
                id={`operator-${index}`}
                sx={{ zIndex: 5000 }}
                onClick={handleOpenDeleteOperator}
              >
                <DeleteOutlineIcon color="secondary" />
              </IconButton>
              <DeleteItemModalId
                open={openDeleteOperator}
                handleClose={handleCloseDeletePartner}
                deleteItemId={deleteOperator}
                message="¿Está seguro que desea eliminar este operador?"
              />
            </>
          )}
        </Box>
        {pii && (
          <>
            <Typography variant="body1" sx={{ color: "#344054" }}>
              <b>Empresa:</b> {pii.company.name}
            </Typography>
            <Typography variant="body1" sx={{ color: "#344054" }}>
              <b>Correo electrónico:</b> {pii.email}
            </Typography>
            <Typography variant="body1" sx={{ color: "#344054" }}>
              <b>{pii.legal_id_type}:</b> {pii.legal_id_code}
            </Typography>
            <Typography variant="body1" sx={{ color: "#344054" }}>
              <b>Rol:</b> {pii.rol}
            </Typography>
            <Typography variant="body1" sx={{ color: "#344054" }}>
              <b>Teléfono:</b> {pii.phone}
            </Typography>
          </>
        )}
      </Box>
    </Grid>
  );
};
