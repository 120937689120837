import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  SearchIcon,
} from "@enerbit/base";
import { EEntity } from "../../../helpers";
import {
  getFrontier,
  getLeadById,
  getSmartBit,
  useAppSelector,
} from "../../../store";
import {
  setPage,
  setPageSize,
  openModal,
} from "../../../store/slices/table-address/tableAddress";
import { useAppDispatch } from "../../../store/store";
import { AddressModal } from "../modals";
import {
  resetLeads,
  setFilter,
} from "../../../store/slices/initial-form/initialForm";
import { resetListOrders } from "../../../store/slices/list-orders/listOrders";

export const SearchOrder = () => {
  const { loading, open } = useAppSelector((state) => state.tableAddress);
  const { entity, filter } = useAppSelector((state) => state.initialForm);
  const dispatch = useAppDispatch();

  const getAddresses = () => {
    dispatch(resetLeads());
    dispatch(resetListOrders())
    const cleanedFilter = filter.replace(/\s+/g, "");

    const dispatchCommon = (isClient: boolean) => {
      dispatch(setPage(isClient ? 0 : 1));
      dispatch(setPageSize(5));
      dispatch(openModal());
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const uuidV4Regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    const numberRegex = /^\d{1,3}(?:[.,]?\d{3})*$/;

    if (emailRegex.test(cleanedFilter)) {
      dispatchCommon(entity === EEntity.Client);
    } else if (uuidV4Regex.test(cleanedFilter)) {
      dispatch(getSmartBit({ id: cleanedFilter, isCreate: true }));
    } else if (cleanedFilter.toLowerCase().includes("frt")) {
      dispatch(getFrontier(cleanedFilter));
    } else if (numberRegex.test(cleanedFilter)) {
      const numericFilter = cleanedFilter.replace(/[.,]/g, "");
      dispatch(getLeadById(numericFilter));
    }
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Grid container spacing={3}>
          <Grid item flex={1}>
            <TextField
              fullWidth
              onKeyDown={(e) => e.key === "Enter" && getAddresses()}
              name={"filter"}
              value={filter}
              onChange={(e) => dispatch(setFilter(e.target.value))}
              sx={{ "& fieldset": { borderRadius: "14px" } }}
              type={EEntity.Smartbit !== entity ? "email" : "text"}
              id={EEntity.Smartbit !== entity ? "email" : "smartbit"}
              disabled={loading}
              variant="outlined"
              placeholder="Buscar"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} thickness={6} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Button
              id="search-lead"
              color="primary"
              variant="contained"
              onClick={getAddresses}
              disabled={loading}
            >
              <SearchIcon />
            </Button>
          </Grid>
        </Grid>
      </FormControl>
      {open && <AddressModal />}
    </Grid>
  );
};
