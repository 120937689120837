import {
  AnimatePresence,
  motion,
  ArrowCircleLeftOutlinedIcon,
  Container,
  Grid,
  IconButton,
  Typography,
  Stack,
  Box,
} from "@enerbit/base";
import { useAppSelector } from "../../../store";
import { ConsultProvider } from "../form-provider";
import { LoadingOrder } from "../loading";
import { Details } from "../../Details";
import { useEffect, useState } from "react";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { useAppDispatch } from "../../../store/store";
import { StyledTabs, StyledTab, TabPanel } from "../../../common";
import { HistoryOrder } from "../history";
import { resetHistory } from "../../../store/slices/history/history";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const ConsultOrder = () => {
  const { selectedOrder, loadingOrder, loadingSmartbit } = useAppSelector(
    (state) => state.initialForm
  );

  const dispatch = useAppDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { showDetails } = useAppSelector((state) => state.detailProgress);

  useEffect(() => {
    dispatch(resetHistory());
  }, [value]);

  return (
    <Container sx={{ py: 5 }}>
      <AnimatePresence mode="wait">
        <motion.div
          key={showDetails ? "withAction" : "withoutAction"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {!showDetails && (
            <>
              <Grid item xs={12}>
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => dispatch(setAction(null))}
                  >
                    <ArrowCircleLeftOutlinedIcon fontSize="large" />
                  </IconButton>
                  <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
                  >
                    Detalle de la orden
                  </Typography>
                </Stack>
              </Grid>
              {!!selectedOrder && !loadingOrder && !loadingSmartbit && (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <StyledTabs value={value} onChange={handleChange}>
                      <StyledTab
                        label={<Box>Consultar orden de servicio</Box>}
                        {...a11yProps(0)}
                      />
                      <StyledTab
                        label={<Box>Historial</Box>}
                        {...a11yProps(1)}
                      />
                    </StyledTabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <ConsultProvider />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <HistoryOrder />
                  </TabPanel>
                </>
              )}
              {loadingOrder && loadingSmartbit && <LoadingOrder />}
            </>
          )}
          {showDetails && <Details />}
        </motion.div>
      </AnimatePresence>
    </Container>
  );
};
