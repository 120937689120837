import { DialogContent } from "@enerbit/base";
import { FC, useEffect } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import * as singleSpa from "single-spa";
import { useAppSelector } from "../../../store";
import { OrderEntityGetOrders } from "../../../models";

interface Props {
  open: boolean;
  handleClose: () => void;
  associatedLead: OrderEntityGetOrders;
}

export const AssociatedModal: FC<Props> = ({
  open,
  handleClose,
  associatedLead,
}) => {
  const { selectedOrder } = useAppSelector((state) => state.initialForm);
  const appName = `app-certifications-${associatedLead.id}`; // example of a unique name

  useEffect(() => {

    if (open) {
      singleSpa.registerApplication({
        name: appName,
        app: () =>
          System.import("@enerBit/certifications").then((module) => {
            if (module.bootstrap && module.mount && module.unmount) {
              return {
                bootstrap: module.bootstrap,
                mount: module.mount,
                unmount: module.unmount,
              };
            } else {
              throw new Error(
                "Module does not export Single SPA lifecycle methods."
              );
            }
          }),
        activeWhen: (location) => location.hash.includes('/orders') && open,
        customProps: { order: selectedOrder, mainLead: associatedLead },
      });

      singleSpa.start();
    }

    return () => {
      if (open && singleSpa.getAppStatus(appName) === "MOUNTED") {
        singleSpa.unloadApplication(appName);
        singleSpa
        .unregisterApplication(appName);
      }
    };
  }, [open, associatedLead, selectedOrder]);

  return (
    <>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
      >
        <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Acta asociada
        </StyledDialogTitle>
        <DialogContent>
          <div id={`single-spa-application:app-certifications-${associatedLead.id}`}></div>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
