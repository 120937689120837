import { Typography } from "@enerbit/base";
import { FC, useEffect, useState } from "react";
import { getOperatorHistory, getOperatorsHistory } from "../../../helpers";

interface Props {
  operator_id?: string;
  order_operators?: { operator_id: string }[];
}

export const HistoryOrderInfo: FC<Props> = ({
  order_operators,
  operator_id,
}) => {
  const [operator, setOperator] = useState<{
    name: string;
    email: string;
  } | null>(null);
  const [operators, setOperators] = useState<
    | {
        name: string;
        email: string;
      }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOperator = async () => {
      if (operator_id) {
        setIsLoading(true);
        const data = await getOperatorHistory(operator_id);
        setIsLoading(false);
        setOperator(data);
      }
    };

    fetchOperator();
  }, [operator_id]);

  useEffect(() => {
    const fetchOperators = async () => {
      if (order_operators) {
        setIsLoading(true);
        const data = await getOperatorsHistory(
          order_operators.map(({ operator_id }) => operator_id)
        );
        setIsLoading(false);
        setOperators(data);
      }
    };

    fetchOperators();
  }, [operator_id]);

  if (!order_operators && !operator_id) return null;

  return (
    <div>
      {!isLoading ? (
        <>
          <Typography
            color="primary"
            sx={{ fontSize: "18px", fontWeight: 700, mb: "12px" }}
          >
            Operadores
          </Typography>
          {operators &&
            operators.map((operator, index) => (
              <p key={index}>
                <b>Nombre {index + 1}:</b> {operator.name}
                <br />
                <b>Correo electrónico: {index + 1}:</b> {operator.email}
              </p>
            ))}
          {operator && (
            <p>
              <b>Nombre:</b> {operator.name}
              <br />
              <b>Correo electrónico:</b> {operator.name}
            </p>
          )}
        </>
      ) : (
        "Cargando..."
      )}
    </div>
  );
};
