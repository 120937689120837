/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, LoadingButton, SaveIcon, Typography } from "@enerbit/base";
import { EntityFiltered } from "../entity";
import { useAppSelector, useAppDispatch } from "../../../store/store";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { EEntity } from "../../../helpers";
import { useEffect } from "react";
import { getActivities, getCertificates, getEntities } from "../../../store";
import { createOrderPermissions } from "../../../store/slices/roles/roles";
import { RoleCode } from "../../../models/IRoles";

export const ResultsFilter = () => {
  const { entity, edges, loadingCertificates, addedEntities } = useAppSelector(
    (state) => state.initialForm
  );
  const { orders } = useAppSelector((state) => state.listOrders);
  const { role, canCreateOrders } = useAppSelector((state) => state.roles);
  const dispatch = useAppDispatch();

  const entityDictionary: Record<EEntity, string> = {
    MP: "Cliente",
    LD: "Lead",
    SMB: "smartBit",
  };

  const getData = async () => {
    dispatch(getActivities());
    dispatch(getEntities());
    dispatch(createOrderPermissions({ role: role as RoleCode, entity }));
    if (entity && entity === EEntity.Client) dispatch(getCertificates(addedEntities[0].id));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container spacing={3} item>
      <Grid item>
        <Typography>Resultados</Typography>
      </Grid>
      <Grid container item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",
          }}>
          <Typography color='primary' sx={{ fontSize: "28px", fontWeight: 400 }}>
            {entity && entityDictionary[entity]}
          </Typography>
          <LoadingButton
            id='generate-os'
            loading={
              orders.LD.loadingOrder ||
              orders.MP.loadingOrder ||
              orders.SMB.loadingOrder ||
              loadingCertificates
            }
            loadingPosition='start'
            startIcon={<SaveIcon />}
            disabled={EEntity.Smartbit === entity && (!edges || edges.length === 0)}
            // disabled={
            //   EEntity.Smartbit === entity &&
            //   (!nodes ||
            //     nodes
            //       .filter(({ type }) => type === "meter")
            //       .some(({ data }) => !data.meter.validated))
            // }
            color='secondary'
            variant='contained'
            onClick={() => {
              if (entity) {
                dispatch(
                  setAction({
                    type: "Create",
                    entity,
                  })
                );
              }
            }}>
            {orders.LD.loadingOrder || orders.MP.loadingOrder || orders.SMB.loadingOrder
              ? "Cargando ordenes de servicio..."
              : "Generar orden de servicio"}
          </LoadingButton>
        </Box>
      </Grid>
      <EntityFiltered />
    </Grid>
  );
};
