import * as yup from "yup";
import {
  finalDateValidation,
  initialDateValidation,
} from "./validationsDateTime";

export const schemaOrder = yup.object().shape({
  state: yup.string().required("Este campo es requerido."),
  city: yup.string().required("Este campo es requerido."),
  planned_date_begin: yup
    .string()
    .nullable()
    .test(
      "initialDate",
      "Fecha y hora inicial inválidas",
      initialDateValidation
    )
    .required("Este campo es requerido."),
  planned_date_end: yup
    .string()
    .nullable()
    .test("finalDate", finalDateValidation)
    .required("Este campo es requerido."),
  form_data: yup.object().shape({
    description: yup.string().required("Este campo es requerido."),
    criteria: yup
      .object({
        cancellation_date_xm: yup
          .string()
          .nullable()
          .test("is-required", "Este campo es requerido.", function (value) {
            return value !== null && value !== undefined ? !!value : true;
          }),
      })
      .nullable(),
  }),
  order_operators: yup
    .array()
    .min(1, "Debe haber al menos un operador.")
    .required("Debe haber al menos un operador."),
  order_entities: yup
    .array()
    .min(1, "Debe haber al menos un predio asociado.")
    .required("Debe haber al menos un predio asociado."),
  order_reason: yup
    .string()
    .nullable()
    .test("is-required", "Este campo es requerido.", function (value) {
      return value !== null && value !== undefined ? !!value : true;
    }),
});

export const schemaNotifications = yup.object().shape({
  full_name: yup.string().required("Este campo es requerido."),
  category: yup.string().required("Este campo es requerido."),
  email: yup
    .string()
    .email("Ingresa un correo válido.")
    .required("Este campo es requerido."),
});
