import { IJsonForm } from "../../models/IDetailsCertificates";

export const mainMeterComunicationsRetired: IJsonForm[] = [
  {
    label: "Serie del módem",
    type: "text",
    name: "serial_number.value",
    sx: {
      xs: 12,
    },
  },
  {
    label: "Fotografía serie del módem",
    type: "image",
    name: "serial_number.picture_url",
  },
  {
    label: "Marca",
    name: "brand",
    type: "text",
    sx: {
      xs: 12,
      md: 6,
    },
  },
];
