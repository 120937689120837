import { Grid, Typography } from "@enerbit/base";
import { FC } from "react";
import { IJsonForm } from "../../../../models/IDetailsCertificates";

export const TitleForm: FC<IJsonForm> = ({ label }) => {
  return (
    <Grid item xs={12}>
      <Typography
        color="primary"
        sx={{ fontWeight: 700, fontSize: "18px", my: 1.3 }}
      >
        {label}
      </Typography>
    </Grid>
  );
};
