import { Grid, Skeleton, Box } from "@enerbit/base";
import { EntityFilteredSkeleton } from "../entity";

export const ResultsFilterSkeleton = () => {
  return (
    <Grid container spacing={2} item>
      <Grid item>
        <Skeleton variant="text" width={110} height={20} />
      </Grid>
      <Grid container item>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",
          }}
        >
          <Skeleton variant="text" width={200} height={60} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Box>
      </Grid>
      <EntityFilteredSkeleton />
    </Grid>
  );
};
